import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const propTypes = {
  isLoading: PropTypes.bool,
};

function Spinner({ isLoading = false }) {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="spinner-overlay">
      <div className="spinner-wrapper">
        <div className="spinner">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      </div>
    </div>
  );
}

Spinner.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    isLoading: state.loading.isLoading,
  };
}

export default connect(mapStateToProps)(Spinner);
