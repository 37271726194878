import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actionCreators from '../ducks/applications';
import AuthorizedApps from '../components/applications/AuthorizedApps';
import ConfirmRevokeModal from '../components/applications/ConfirmRevokeModal';
import Header from '../components/dashboard/Header';
import '!style-loader!css-loader!sass-loader!../../styles/applications.scss';
import '!style-loader!css-loader!sass-loader!../../styles/applications-responsive.scss';

const propTypes = {
  authToken: PropTypes.string.isRequired,
  init: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.shape({
    trackingId: PropTypes.string,
    sip: PropTypes.shape({
      uri: PropTypes.string,
      password: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    totalNumberBurners: PropTypes.number,
    credits: PropTypes.number,
    version: PropTypes.number,
    id: PropTypes.string,
    dateCreated: PropTypes.number,
    carrierName: PropTypes.string,
    createdSample: PropTypes.bool,
    lastUpdatedDate: PropTypes.number,
    countryCode: PropTypes.string,
    superUser: PropTypes.bool,
    platform: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    lifetimeSubscriptions: PropTypes.number,
  }).isRequired,
};

/**
 * Page for authorizations
 */
class ApplicationsPage extends Component {
  componentDidMount() {
    const { user, authToken, location, init } = this.props;
    const { router } = this.context;

    if (!location.query.dev) {
      router.push('/');
      return;
    }
    if (user) {
      init(user.id, authToken);
    } else {
      router.push('/');
    }
  }

  render() {
    return (
      <div className="row main-app">
        <div className="large-12 columns">
          <div id="applications-container">
            <Header currentPage="ApplicationsPage" />

            <div className="main-container">
              <div className="row">
                <div className="large-12 columns">
                  <h1 className="burner-name title-heading">Applications</h1>
                </div>
              </div>

              <div className="row">
                <div className="large-10 columns">
                  <p>
                    Make Burner even better! Authorized Apps have access to your Burners and enhance the functionality /
                    experience with Burner. You can remove your authorized applications at any time.
                  </p>
                  <a className="doc-link" href="http://www.burnerapp.com/developer">
                    View full list of third party applications.
                  </a>
                </div>
              </div>

              <div className="row section">
                <div className="large-12 columns">
                  <h3 className="title">Your authorized applications</h3>

                  <AuthorizedApps />
                </div>
              </div>

              <ConfirmRevokeModal />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ApplicationsPage.propTypes = propTypes;
ApplicationsPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authToken: state.auth.authToken,
    user: state.auth.user,
  };
}

const mapDispatchToProps = actionCreators;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationsPage);
