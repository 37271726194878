import React from 'react';
import PropTypes from 'prop-types';

import Header from '../components/common/Header';
import Footer from '../components/oauth/Footer';
import NumberPicker from '../components/purchase/NumberPicker';
import '!style-loader!css-loader!sass-loader!../../styles/subscribe-alt-purchase.scss';
import '!style-loader!css-loader!sass-loader!../../styles/subscribe-alt-purchase-responsive.scss';
import '!style-loader!css-loader!sass-loader!../../styles/number-picker-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/my-corporation.scss';

const propTypes = {
  areaCode: PropTypes.string.isRequired,
  isNumberTaken: PropTypes.bool.isRequired,
  reprovisionNumber: PropTypes.func.isRequired,
  setSelectedNumber: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    totalConcurrentBurners: PropTypes.number,
    totalBurnersAllowedInPeriod: PropTypes.number,
    sku: PropTypes.string,
  }),
};

function PickNumberPage({
  areaCode,
  isNumberTaken,
  reprovisionNumber,
  setSelectedNumber,
  subscription = null,
}) {
  return (
    <div>
      <Header />
      <div className="Main__container Main__container--minHeight grid-x grid-padding-x align-center-middle">
        <div className="cell large-8 small-10">
          <div className="text-center number-picker">
            <div>
              <h1>Choose a phone number</h1>
            </div>
            <h4 className="Error__text">
              {isNumberTaken
                ? "Unfortunately the number you've selected is no longer available. Please select another number."
                : ''}
            </h4>
            <NumberPicker
              className="u-margin-top-35px"
              areaCode={areaCode}
              onChosenNumber={(number) => {
                if (isNumberTaken) {
                  reprovisionNumber(number, subscription);
                } else {
                  setSelectedNumber(number);
                }
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

PickNumberPage.propTypes = propTypes;

export default PickNumberPage;
