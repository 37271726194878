import React from 'react';
import PropTypes from 'prop-types';

import Header from '../components/common/Header';
import Footer from '../components/oauth/Footer';
import AreaCodePicker from '../components/purchase/AreaCodePicker';
import '!style-loader!css-loader!sass-loader!../../styles/subscribe-alt-purchase.scss';
import '!style-loader!css-loader!sass-loader!../../styles/subscribe-alt-purchase-responsive.scss';
import '!style-loader!css-loader!sass-loader!../../styles/number-picker.scss';

const propTypes = {
  areaCode: PropTypes.string.isRequired,
};

function SearchPage({ areaCode }) {
  return (
    <div>
      <Header />
      <div className="Main__container Main__container--minHeight grid-x grid-padding-x align-center-middle">
        <div className="text-center cell large-10 small-10">
          <div className="number-picker">
            <h1>Choose an area code</h1>
            <h2 className="h4">
              Burner includes unlimited calls, texts, and pics plus spam
              blocking.
            </h2>
            <AreaCodePicker areaCode={areaCode} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

SearchPage.propTypes = propTypes;

export default SearchPage;
