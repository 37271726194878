import { PAYMENT_UPDATED } from './dashboard';

export const CLOSE_SUCCESS_MODAL = 'burner-web/successModal/CLOSE_SUCCESS_MODAL';
export const SUBSCRIPTION_CANCELLED = 'burner-web/successModal/SUBSCRIPTION_CANCELLED';
export const RECEIVE_OUTBOUND_WEBHOOK_TEST = 'burner-web/successModal/RECEIVE_OUTBOUND_WEBHOOK_TEST';

export default function reducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_OUTBOUND_WEBHOOK_TEST:
      return Object.assign({}, state, {
        showSuccess: true,
        title: action.title,
        message: action.message,
      });
    case PAYMENT_UPDATED:
      return Object.assign({}, state, {
        showSuccess: true,
        message: action.message,
      });
    case SUBSCRIPTION_CANCELLED:
      return Object.assign({}, state, {
        showSuccess: true,
        title: action.title,
        message: action.message,
      });
    case CLOSE_SUCCESS_MODAL:
      return Object.assign({}, state, {
        showSuccess: false,
      });
    default:
      return state;
  }
}

export function closeModal() {
  return {
    type: CLOSE_SUCCESS_MODAL,
  };
}
