import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SearchPage from '../pages/SearchPage';

const propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

class SearchPageContainer extends Component {
  constructor(props) {
    super(props);
    const { location, params } = this.props;

    this.areaCode = params.areaCode || location.query.areacode || '';
  }

  render() {
    return <SearchPage areaCode={this.areaCode} />;
  }
}

SearchPageContainer.propTypes = propTypes;

export default SearchPageContainer;
