import { BASE_URL } from '../constants';
import { hasError } from '../ducks/error';
import { extractError } from '../utils/request';
import { requesting } from '../ducks/general';
import { showSuccessModal } from '../ducks/dashboard';
import axios from 'axios';
import { reset } from 'redux-form';
import { trackSitebuilderSendSms } from '../utils/analytics';

export const RECEIVE_INSTANCE_BURNER_DETAILS = 'burner-web/instance/RECEIVE_INSTACE_BURNER_DETAILS';

export default function reducer(state = { toggled: false }, action) {
  switch (action.type) {
    case RECEIVE_INSTANCE_BURNER_DETAILS:
      return Object.assign({}, state, {
        burner: action.burner,
      });
    default:
      return state;
  }
}

export function receiveInstanceBurnerDetails(burner) {
  return {
    type: RECEIVE_INSTANCE_BURNER_DETAILS,
    burner,
  };
}

export function sendMessageToInstanceBurner(authToken, from, message) {
  return (dispatch) => {
    dispatch(requesting());

    axios
      .post(
        `${BASE_URL}/burners/instance/messages`,
        { from, message },
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then(() => {
        trackSitebuilderSendSms();
        dispatch(reset('sitebuilder'));
        dispatch(showSuccessModal('Your message has been sent.'));
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status));
      });
  };
}

export function getInstanceBurner(authToken) {
  return (dispatch) => {
    dispatch(requesting());

    axios
      .get(`${BASE_URL}/burners/instance`, {
        headers: {
          Authorization: authToken,
        },
      })
      .then((resp) => {
        dispatch(receiveInstanceBurnerDetails(resp.data));
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status));
      });
  };
}
