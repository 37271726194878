import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Header from '../components/common/Header';
import Footer from '../components/oauth/Footer';
import SubscribeForm from '../components/purchase/SubscribeForm';
import VerifyCodeModal from '../components/modal/VerifyCodeModal';
import { renderLocalNumber } from '../utils/phone';
import '!style-loader!css-loader!sass-loader!../../styles/subscribe-alt-purchase.scss';
import '!style-loader!css-loader!sass-loader!../../styles/subscribe-alt-purchase-responsive.scss';
import '!style-loader!css-loader!sass-loader!../../styles/number-picker.scss';
import '!style-loader!css-loader!sass-loader!../../styles/my-corporation.scss';

const propTypes = {
  cellPhone: PropTypes.string.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  selectedNumber: PropTypes.string.isRequired,
};

class PurchasePage extends Component {
  render() {
    const { selectedNumber, handleSubmitForm } = this.props;

    return (
      <div>
        <Header />
        <div className="Main__container Main__container--minHeight grid-x grid-padding-x align-center-middle">
          <div className="cell large-8 small-10">
            <div className="Subscription__checkout">
              <div className="SubscriptionCheckout__infoNumber text-center">
                <h1>Here’s your new number</h1>
                <div className="SubscriptionNumber__highlight">
                  {renderLocalNumber(selectedNumber)}
                </div>
                <h4>Now create an account to save it!</h4>
              </div>
              <SubscribeForm
                onSubmitSubscribeForm={handleSubmitForm}
                ref="form"
              />
            </div>
          </div>
        </div>
        <VerifyCodeModal />
        <Footer />
      </div>
    );
  }
}

PurchasePage.propTypes = propTypes;

export default PurchasePage;
