import cookie from 'react-cookie';
import { AUTHENTICATION_KEY } from '../constants';
import { LOGOUT_SUCCESS, USER_VERIFIED } from './verify';
import { getCurrentUser } from '../utils/user';

export const SET_REDIRECT_INFO = 'burner-web/auth/SET_REDIRECT_INFO';
export const LOGGED_IN = 'burner-web/auth/LOGGED_IN';
export const SAMPLE_CREATED = 'burner-web/auth/SAMPLE_CREATED';

export default function reducer(
  state = {
    redirectTo: '/dashboard',
    isFetching: false,
    authToken: cookie.load(AUTHENTICATION_KEY),
    user: getCurrentUser(),
    isAuthenticated: cookie.load(AUTHENTICATION_KEY) ? true : false,
  },
  action
) {
  switch (action.type) {
    case USER_VERIFIED:
      return Object.assign({}, state, {
        authToken: action.authToken,
        user: action.user,
        isAuthenticated: true,
      });
    case LOGGED_IN:
      return Object.assign({}, state, {
        user: action.user,
        isAuthenticated: true,
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isAuthenticated: false,
      });
    case SET_REDIRECT_INFO:
      return Object.assign({}, state, {
        redirectTo: action.redirectTo,
      });
    case SAMPLE_CREATED:
      const newUser = Object.assign({}, state.user);
      newUser.createdSample = true;
      return Object.assign({}, state, {
        user: newUser,
      });
    default:
      return state;
  }
}

/**
 * Stores a redirect path info, for when lifecycle events such as sign up or choose number flow is complete.
 */
export function setRedirectPath(redirectTo) {
  return {
    type: SET_REDIRECT_INFO,
    redirectTo,
  };
}

export function sampleCreated() {
  return {
    type: SAMPLE_CREATED,
  };
}
