import React from 'react';
import PropTypes from 'prop-types';

import Header from '../components/common/Header';
import Footer from '../components/oauth/Footer';
import ReceiptWithNumber from '../components/purchase/ReceiptWithNumber';
import '!style-loader!css-loader!sass-loader!../../styles/burner-details-rebrand.scss';

const propTypes = {
  discountAmount: PropTypes.number,
  freeTrialLength: PropTypes.number,
  selectedNumber: PropTypes.string.isRequired,
  subscription: PropTypes.shape({
    totalConcurrentBurners: PropTypes.number,
    totalBurnersAllowedInPeriod: PropTypes.number,
    sku: PropTypes.string,
  }),
  userEmail: PropTypes.string,
};

const defaultProps = {
  discountAmount: 0,
  freeTrialLength: null,
  subscription: null,
  userEmail: null,
};

function ConfirmationPage({ subscription, discountAmount, userEmail, selectedNumber, freeTrialLength }) {
  return (
    <div>
      <Header />
      <div className="Confirmation__container">
        <ReceiptWithNumber
          discountAmount={discountAmount}
          freeTrialLength={freeTrialLength}
          selectedNumber={selectedNumber}
          subscription={subscription}
          userEmail={userEmail}
        />
        <Footer />
      </div>
    </div>
  );
}

ConfirmationPage.propTypes = propTypes;
ConfirmationPage.defaultProps = defaultProps;

export default ConfirmationPage;
