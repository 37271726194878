import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actionCreators from '../ducks/dashboard';
import { canCreateSample } from '../utils/createSample';
import AccountSummary from '../components/dashboard/AccountSummary';
import AppButtons from '../components/AppButtons';
import BurnersList from '../components/dashboard/BurnersList';
import CancelSubscriptionModal from '../components/modal/CancelSubscriptionModal';
import LoggedInHeader from '../components/common/LoggedInHeader';
import Footer from '../components/oauth/Footer';
// import LinkTexting from '../components/LinkTexting';
import ReinstateSubscriptionModal from '../components/modal/ReinstateSubscriptionModal';
import SuccessModal from '../components/modal/SuccessModal';
import UpgradeBox from '../components/dashboard/UpgradeBox';
import '!style-loader!css-loader!sass-loader!../../styles/dashboard-rebrand.scss';

const propTypes = {
  authToken: PropTypes.string.isRequired,
  burners: PropTypes.arrayOf(
    PropTypes.shape({
      callerIdEnabled: PropTypes.bool,
      remainingTexts: PropTypes.number,
      name: PropTypes.string,
      expirationDate: PropTypes.number,
      phoneNumberId: PropTypes.string,
      hexColor: PropTypes.number,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          featureName: PropTypes.string,
        })
      ),
      expirationWarning: PropTypes.shape({
        id: PropTypes.number,
      }),
      alias: PropTypes.string,
      version: PropTypes.number,
      id: PropTypes.string,
      autoReplyActive: PropTypes.bool,
      dateCreated: PropTypes.number,
      lastUpdatedDate: PropTypes.number,
      renewalDate: PropTypes.number,
      ringer: PropTypes.bool,
      autoReplyType: PropTypes.number,
      remainingMinutes: PropTypes.number,
      productId: PropTypes.string,
      userId: PropTypes.string,
      autoReplyText: PropTypes.string,
      totalMinutes: PropTypes.number,
      notifications: PropTypes.bool,
      useSip: PropTypes.bool,
      totalTexts: PropTypes.number,
    })
  ),
  isAuthenticated: PropTypes.bool.isRequired,
  loadUserDetails: PropTypes.func.isRequired,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      price: PropTypes.number,
      store: PropTypes.string,
      burnersAssignedInPeriod: PropTypes.number,
      id: PropTypes.string,
      receipt: PropTypes.string,
      renewalDate: PropTypes.number,
      creationDate: PropTypes.number,
      burnerIds: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  user: PropTypes.shape({
    trackingId: PropTypes.string,
    sip: PropTypes.shape({
      uri: PropTypes.string,
      password: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    totalNumberBurners: PropTypes.number,
    credits: PropTypes.number,
    version: PropTypes.number,
    id: PropTypes.string,
    dateCreated: PropTypes.number,
    carrierName: PropTypes.string,
    createdSample: PropTypes.bool,
    lastUpdatedDate: PropTypes.number,
    countryCode: PropTypes.string,
    superUser: PropTypes.bool,
    platform: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    lifetimeSubscriptions: PropTypes.number,
  }).isRequired,
};

const defaultProps = {
  burners: null,
  subscriptions: null,
};

class DashboardPage extends Component {
  componentDidMount() {
    const { user, loadUserDetails, authToken } = this.props;
    const { router } = this.context;

    if (user) {
      loadUserDetails(authToken, user.id);
    } else {
      router.push('/');
    }
  }

  componentWillReceiveProps(nextProps) {
    const { router } = this.context;

    if (canCreateSample(nextProps)) {
      router.push('/premium-3');
    } else if (!nextProps.isAuthenticated) {
      router.push('/');
    }
  }

  render() {
    const { user, burners, subscriptions } = this.props;

    return (
      <div>
        <LoggedInHeader />
        <div className="Dashboard__container grid-x grid-padding-x align-center-middle">
          <AccountSummary subscriptions={subscriptions} user={user} />
        </div>
        <div className="Dashboard__secondaryContainer Dashboard__container--bgWhite grid-x grid-padding-x align-center-middle">
          <div className="cell large-7 small-10">
            <h3>Your phone numbers</h3>
            <div className="grid-x grid-padding-x">
              <div className="cell large-7">
                <p>
                  You have {burners ? burners.length : '0'} active phone{' '}
                  {burners && burners.length === 1 ? 'number' : 'numbers'}. Visit
                  the Burner app on mobile to create new or extend existing
                  numbers.
                </p>
              </div>
            </div>

            <div className="grid-x grid-padding-x align-left-middle">
              <BurnersList burners={burners} subscriptions={subscriptions} />
            </div>
          </div>

          <CancelSubscriptionModal />
          <ReinstateSubscriptionModal />
          <SuccessModal />
        </div>
        <div className="TileContainer">
          <UpgradeBox burners={burners} subscriptions={subscriptions} />
        </div>
        <div className="cell large-5">
          <AppButtons />
          {/*<LinkTexting userPhoneNumber={user.phoneNumber} />*/}
        </div>
        <Footer />
      </div>
    );
  }
}

DashboardPage.propTypes = propTypes;
DashboardPage.defaultProps = defaultProps;
DashboardPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authToken: state.auth.authToken,
    burners: state.dashboardPage.burners,
    isAuthenticated: state.auth.isAuthenticated,
    subscriptions: state.dashboardPage.subscriptions,
    user: state.auth.user,
  };
}

const mapDispatchToProps = actionCreators;

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
