function hasFeature(burner, featureName) {
  if (!burner) {
    return false;
  }
  const matching = burner.features.filter((feature) => feature.featureName === featureName);
  return matching.length > 0;
}

export function isUnlimited(burner) {
  return hasFeature(burner, 'unlimitedUsage');
}

export function isMms(burner) {
  return hasFeature(burner, 'mms');
}
