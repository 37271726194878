export const SIGN_UP = 'burner-app/signup/SIGNUP';

/**
 * Reducer for the sign up/in page
 */
export default function reducer(state = {}, action = {}) {
  const { type } = action;

  switch (type) {
    case SIGN_UP:
      return Object.assign({}, state, {});
    default:
      return state;
  }
}
