import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import moment from 'moment';

import * as actionCreators from '../../ducks/dashboard';
import { isStripeSubscription } from '../../utils/subscription';

const propTypes = {
  authToken: PropTypes.string.isRequired,
  dismissReinstateSubsModal: PropTypes.func.isRequired,
  reinstateSubscription: PropTypes.func.isRequired,
  reinstateSubscriptionClicked: PropTypes.bool,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      price: PropTypes.number,
      store: PropTypes.string,
      burnersAssignedInPeriod: PropTypes.number,
      id: PropTypes.string,
      receipt: PropTypes.string,
      renewalDate: PropTypes.number,
      creationDate: PropTypes.number,
      burnerIds: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  user: PropTypes.shape({
    trackingId: PropTypes.string,
    sip: PropTypes.shape({
      uri: PropTypes.string,
      password: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    totalNumberBurners: PropTypes.number,
    credits: PropTypes.number,
    version: PropTypes.number,
    id: PropTypes.string,
    dateCreated: PropTypes.number,
    carrierName: PropTypes.string,
    createdSample: PropTypes.bool,
    lastUpdatedDate: PropTypes.number,
    countryCode: PropTypes.string,
    superUser: PropTypes.bool,
    platform: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    lifetimeSubscriptions: PropTypes.number,
  }).isRequired,
};

const defaultProps = {
  reinstateSubscriptionClicked: false,
  subscriptions: null,
};

class ReinstateSubscriptionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  componentDidUpdate(prevProps) {
    const { reinstateSubscriptionClicked } = this.props;

    if (prevProps.reinstateSubscriptionClicked === reinstateSubscriptionClicked) {
      return;
    }
    if (reinstateSubscriptionClicked) {
      this.openModal();
    } else if (!reinstateSubscriptionClicked) {
      this.closeModal();
    }
  }

  openModal() {
    this.setState(() => ({
      modalIsOpen: true,
    }));
  }

  close() {
    this.setState(() => ({
      modalIsOpen: false,
    }));
  }

  closeModal() {
    const { dismissReinstateSubsModal } = this.props;

    dismissReinstateSubsModal();
    this.close();
  }

  renderContent() {
    const { subscriptions, reinstateSubscription, authToken, user } = this.props;

    if (isStripeSubscription(subscriptions[0])) {
      return (
        <div>
          <h2 className="renewal-title">Renew your Burner subscription?</h2>
          <p className="renewal-message">
            Your Burner subscription is set to expire on {moment(subscriptions[0].renewalDate).format('MM/DD/YYYY')}. To
            avoid a disruption of your service and renew your Burner subscription, click &quot;Renew&quot;.
          </p>
          <button type="submit" onClick={this.closeModal} className="cancel-button submit">
            Nevermind
          </button>
          <button
            type="submit"
            onClick={() => reinstateSubscription(authToken, user.id, subscriptions[0].id)}
            className="flat button selection-button submit"
          >
            Renew
          </button>
        </div>
      );
    }

    return (
      <div>
        <h2 className="renewal-title">Not a web subscription</h2>
        <p className="renewal-message">
          To reinstate your subscription or change any of the associated information please visit your account through
          the App Store or Google Play Store.
        </p>
        <button type="submit" onClick={this.closeModal} className="flat button selection-button submit">
          Ok
        </button>
      </div>
    );
  }

  render() {
    const { modalIsOpen } = this.state;
    const { subscriptions } = this.props;

    if (!subscriptions || !subscriptions[0]) {
      return null;
    }

    return (
      <Modal
        contentLabel="Reinstate Subscription Modal"
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        className="burner-modal"
        overlayClassName="burner-modal-overlay"
      >
        <div id="reinstateSubscribeModal">
          <div className="row">
            <div className="large-12 columns">{this.renderContent()}</div>
          </div>
        </div>
      </Modal>
    );
  }
}

ReinstateSubscriptionModal.propTypes = propTypes;
ReinstateSubscriptionModal.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    authToken: state.auth.authToken,
    reinstateSubscriptionClicked: state.dashboardPage.reinstateSubscriptionClicked,
    subscriptions: state.dashboardPage.subscriptions,
    user: state.auth.user,
  };
}

const mapDispatchToProps = actionCreators;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReinstateSubscriptionModal);
