import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import * as actionCreators from '../ducks/verify';
import * as authActions from '../ducks/auth';
import * as errorActions from '../ducks/error';
import {
  OAUTH_REDIRECT_PARAMS,
  NUX_NUMBER_VERIFICATION_WEB,
  CUSTOM_WEB_EVENT,
} from '../constants';
import Footer from '../components/oauth/Footer';
import OauthAwareAnchor from '../components/oauth/OauthAwareAnchor';
import OauthAwareButton from '../components/oauth/OauthAwareButton';
import OauthAwareHeader from '../components/oauth/OauthAwareHeader';
import OauthPage from './OauthPage';

const propTypes = {
  clientInfo: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    sampleSku: PropTypes.string,
    thumbnailURL: PropTypes.string,
  }),
  codeWasSent: PropTypes.bool,
  error: PropTypes.shape({
    isUnauthorized: PropTypes.bool,
    errorMessage: PropTypes.string,
    shouldLog: PropTypes.bool,
    goBack: PropTypes.func,
    redirectBackAction: PropTypes.func,
  }).isRequired,
  fetchVerifyCode: PropTypes.func.isRequired,
  isResend: PropTypes.bool,
  params: PropTypes.object.isRequired,
  redirectTo: PropTypes.string.isRequired,
  resetVerifyCodeError: PropTypes.func.isRequired,
  user: PropTypes.shape({
    trackingId: PropTypes.string,
    sip: PropTypes.shape({
      uri: PropTypes.string,
      password: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    totalNumberBurners: PropTypes.number,
    credits: PropTypes.number,
    version: PropTypes.number,
    id: PropTypes.string,
    dateCreated: PropTypes.number,
    carrierName: PropTypes.string,
    createdSample: PropTypes.bool,
    lastUpdatedDate: PropTypes.number,
    countryCode: PropTypes.string,
    superUser: PropTypes.bool,
    platform: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    lifetimeSubscriptions: PropTypes.number,
  }),
  userVerified: PropTypes.bool,
  validateVerifyCode: PropTypes.func.isRequired,
  verifyErrorMessage: PropTypes.string,
};

const defaultProps = {
  clientInfo: null,
  codeWasSent: false,
  isResend: false,
  userVerified: false,
  user: null,
  verifyErrorMessage: null,
};

class VerifyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      verifyCode: '',
    };

    this.handleResendClick = this.handleResendClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerifyCodeChange = this.handleVerifyCodeChange.bind(this);
    this.maybeRenderResentMessage = this.maybeRenderResentMessage.bind(this);
    this.renderVerifyCodeErrorSection =
      this.renderVerifyCodeErrorSection.bind(this);
    this.renderPinInput = this.renderPinInput.bind(this);
  }

  componentDidMount() {
    const { fetchVerifyCode, params } = this.props;
    const { router } = this.context;

    fetchVerifyCode(params.phone, false, null, () => {
      router.goBack();
    });
  }

  componentWillReceiveProps(nextProps) {
    const { verifyCode } = this.state;
    const { router } = this.context;

    if (nextProps.userVerified) {
      dataLayer.push({
        event: CUSTOM_WEB_EVENT,
        customWebEventName: NUX_NUMBER_VERIFICATION_WEB,
      });

      const oauthParams = localStorage.getItem(OAUTH_REDIRECT_PARAMS);
      if (oauthParams && nextProps.redirectTo === OauthPage.PATH) {
        // for oauth there are query params that need to be appended back after successful auth.
        localStorage.removeItem(OAUTH_REDIRECT_PARAMS);
        router.push(nextProps.redirectTo + oauthParams);
      } else {
        router.push(nextProps.redirectTo);
      }
    }

    if (nextProps.error.errorMessage && verifyCode) {
      this.setState(() => ({
        verifyCode: '',
      }));
    }
  }

  handleResendClick(event) {
    const { fetchVerifyCode, params } = this.props;

    fetchVerifyCode(params.phone, true);
    event.preventDefault();
  }

  handleSubmit(event) {
    const { verifyCode } = this.state;
    const { validateVerifyCode, params } = this.props;

    event.preventDefault();
    if (!verifyCode) {
      this.setState(() => ({
        errorMessage: "That verification code isn't valid.",
      }));
      return;
    }
    if (verifyCode.length !== 6) {
      this.setState(() => ({
        errorMessage: 'Verification code should be six digits.',
      }));
      return;
    }
    validateVerifyCode(params.phone, verifyCode);
  }

  handleVerifyCodeChange(event) {
    const { resetVerifyCodeError } = this.props;
    const { value } = event.target;

    this.setState(() => ({
      errorMessage: null,
      verifyCode: value,
    }));

    if (value === '') {
      resetVerifyCodeError();
    }
  }

  maybeRenderResentMessage() {
    const { isResend } = this.props;

    if (!isResend) {
      return null;
    }
    return (
      <div className="u-margin-top-35px">
        <div data-alert="" className="info box">
          <span className="title">Verification code resent!</span>
        </div>
      </div>
    );
  }

  renderVerifyCodeErrorSection() {
    const { errorMessage } = this.state;
    const { verifyErrorMessage } = this.props;

    if (!errorMessage && !verifyErrorMessage) {
      return null;
    }
    return (
      <div className="u-margin-top-35px error error-msg">
        {errorMessage || verifyErrorMessage}
      </div>
    );
  }

  renderPinInput() {
    const { errorMessage } = this.state;
    const { codeWasSent, clientInfo, verifyErrorMessage } = this.props;

    if (codeWasSent === false) {
      return null;
    }

    return (
      <div>
        <OauthAwareHeader clientInfo={clientInfo} />
        <div className="Main__container Main__container--minHeight grid-x grid-padding-x align-center-middle">
          <div className="cell large-8 small-10">
            <form onSubmit={this.handleSubmit}>
              <h1 className="desc text-center">
                Enter verification code (check your texts)
              </h1>
              <div className="Form__inputWrapper u-margin-top-35px grid-x align-center align-bottom">
                <label
                  htmlFor="VerificationCode"
                  className="Form__fieldWrapper cell large-4 auto"
                >
                  <span className="Input__label">Verification code</span>
                  <input
                    name="VerificationCode"
                    className={`main-input  ${
                      errorMessage || verifyErrorMessage ? 'error-input' : ''
                    }`}
                    type="tel"
                    maxLength="6"
                    placeholder="######"
                    format="######"
                    onChange={this.handleVerifyCodeChange}
                  />
                </label>
                <div className="Form__submitWrapper cell shrink">
                  <OauthAwareAnchor
                    clientInfo={clientInfo}
                    onClick={this.handleResendClick}
                    className="Button__resendCode button button--textOnly"
                    anchorText="Resend code"
                  />
                </div>
              </div>
              <div className="Form__outputWrapper grid-x align-center text-center">
                <div className="cell large-8">
                  {this.maybeRenderResentMessage()}
                  {this.renderVerifyCodeErrorSection()}
                </div>
              </div>
              <div className="Text__disclaimer u-margin-top-35px grid-x align-center text-center">
                <div className="cell large-5 small-auto">
                  <p>
                    By continuing, you acknowledge that you agree to our{' '}
                    <Link to="http://www.burnerapp.com/terms-of-service">
                      terms of service
                    </Link>{' '}
                    and{' '}
                    <Link to="http://www.burnerapp.com/privacy">
                      privacy policy.
                    </Link>{' '}
                    Subscription auto-renews monthly.
                  </p>
                  <div className="Form__submitWrapper u-margin-top-35px  cell large-5 small-auto">
                    <OauthAwareButton
                      clientInfo={clientInfo}
                      className="button button--notRounded"
                      buttonText="Next"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  render() {
    return (
      <div className="row main-app">
        <div className="large-12 columns">{this.renderPinInput()}</div>
      </div>
    );
  }
}

VerifyPage.propTypes = propTypes;
VerifyPage.defaultProps = defaultProps;
VerifyPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    clientInfo: state.oauthPage.clientInfo,
    codeWasSent: state.verifyPage.codeWasSent,
    error: state.error,
    isResend: state.verifyPage.isResend,
    redirectTo: state.auth.redirectTo,
    user: state.auth.user,
    userVerified: state.verifyPage.userVerified,
    verifyErrorMessage: state.verifyPage.verifyErrorMessage,
  };
}

const mapDispatchToProps = {
  ...actionCreators,
  ...authActions,
  ...errorActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPage);
