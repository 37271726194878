import axios from 'axios';
import { BASE_URL } from '../constants';
import { SHOW_CONFIRM_DEV_CONNECTION } from './confirm';
import { RECEIVE_OUTBOUND_WEBHOOK_TEST } from './successModal';
import * as DashboardActionTypes from './dashboard';
import { hasError } from './error';
import { requesting, getSubscriptionInfo, getBurnerConnections, getDevConnectionSettings } from './general';
import { extractError } from '../utils/request';

export const TOGGLE_DEV_CONNECTION = 'burner-web/burnerDetails/TOGGLE_DEV_CONNECTION';
export const RECEIVE_BURNER_DETAILS = 'burner-web/burnerDetails/RECEIVE_BURNER_DETAILS';
export const NO_CONNECTION_ACTION = 'burner-web/burnerDetails/NO_CONNECTION_ACTION';
export const RECEIVE_CONNECTION_SETTINGS = 'burner-web/burnerDetails/RECEIVE_CONNECTION_SETTINGS';
export const DEV_CONNECT_SUCCESS = 'burner-web/burnerDetails/DEV_CONNECT_SUCCESS';
export const DEV_UNLINK_SUCCESS = 'burner-web/burnerDetails/DEV_UNLINK_SUCCESS';

export default function reducer(state = { toggled: false }, action) {
  switch (action.type) {
    case TOGGLE_DEV_CONNECTION:
      return Object.assign({}, state, {
        toggled: !state.toggled,
      });
    case RECEIVE_BURNER_DETAILS:
      return Object.assign({}, state, {
        burner: action.burner,
      });
    case DashboardActionTypes.RECEIVE_SUBSCRIPTIONS:
      return Object.assign({}, state, {
        subscriptions: action.subscriptions,
        rules: action.rules,
      });
    case NO_CONNECTION_ACTION:
      return Object.assign({}, state, {
        toggled: false,
      });
    case RECEIVE_CONNECTION_SETTINGS:
      return Object.assign({}, state, {
        toggled: true,
        developerSettings: action.developerSettings,
      });
    case DEV_CONNECT_SUCCESS:
      return Object.assign({}, state, {
        toggled: true,
      });
    case DEV_UNLINK_SUCCESS:
      return Object.assign({}, state, {
        toggled: false,
      });
    default:
      return state;
  }
}

export function confirmDevConnection(toggled, burnerId) {
  if (toggled) {
    return {
      burnerId,
      type: SHOW_CONFIRM_DEV_CONNECTION,
      title: 'Turn on Developer Connection?',
      text: 'Enable the Developer connection to add incoming and outgoing webhooks to your Burner.',
      toggleOn: true,
    };
  }

  return {
    burnerId,
    type: SHOW_CONFIRM_DEV_CONNECTION,
    title: 'Unlink Developer Connection?',
    text: "Are you sure you want to unlink the connections? Your connection will no longer work if it's unlinked.",
    toggleOn: false,
  };
}

export function receiveBurnerDetails(burner) {
  return {
    type: RECEIVE_BURNER_DETAILS,
    burner,
  };
}

export function updateDevConnectionSettings(userId, authToken, burnerId, settings) {
  return (dispatch) => {
    dispatch(requesting());

    axios
      .put(
        `${BASE_URL}/user/${userId}/burners/${burnerId}/integration/settings/com.adhoclabs.connections.developer`,
        settings,
        {
          headers: {
            Authentication: authToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(() => dispatch(getDevConnectionSettings(userId, authToken, burnerId)))
      .catch((error) => dispatch(hasError(extractError(error), error.status)));
  };
}

export function associateBurnerWithSub(authToken, userId, subscriptionId, burnerId) {
  return (dispatch) => {
    dispatch(requesting());

    axios
      .put(
        `${BASE_URL}/user/${userId}/subscriptions/${subscriptionId}/burners/${burnerId}`,
        {},
        {
          headers: {
            Authentication: authToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(() => {
        dispatch(getSubscriptionInfo(authToken, userId));
      })
      .catch((error) => dispatch(hasError(extractError(error), error.status)));
  };
}

export function getBurnerDetails(authToken, userId, burnerId) {
  return (dispatch) => {
    dispatch(requesting());

    // gets the user first, then gets the list of burners for that user
    axios
      .get(`${BASE_URL}/user/${userId}/burners/${burnerId}`, {
        headers: {
          Authentication: authToken,
        },
      })
      .then((resp) => {
        dispatch(receiveBurnerDetails(resp.data));
        dispatch(getSubscriptionInfo(authToken, userId));
        dispatch(getBurnerConnections(authToken, userId, burnerId));
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status));
      });
  };
}

function receiveOutboundWebhookTest() {
  return {
    type: RECEIVE_OUTBOUND_WEBHOOK_TEST,
    title: 'Success!',
    message: 'Outbound test webhook sent successfully!',
  };
}

export function outboundWebhookTest(userId, authToken, burnerId) {
  return (dispatch) => {
    dispatch(requesting());

    // gets the user first, then gets the list of burners for that user
    axios
      .put(
        `${BASE_URL}/user/${userId}/burners/${burnerId}/webhooktest`,
        {},
        {
          headers: {
            Authentication: authToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(() => {
        dispatch(receiveOutboundWebhookTest());
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status));
      });
  };
}
