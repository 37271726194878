import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes, SubmissionError } from 'redux-form';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';

import {
  createValidator,
  required,
  validateCvc,
  validateExpMonth,
  validateExpYear,
  validateCreditCard,
  validateZip,
} from '../../utils/validation';

const renderInput = field =>
  <div>
    <input
      {...field.input}
      type={field.type}
      id={field.id}
      onKeyPress={field.onKeyPress}
      placeholder={field.placeholder}
    />
    {field.meta.touched &&
     field.meta.error &&
     <span className="error">{field.meta.error}</span>}
  </div>

const renderNumberFormat = field =>
  <div>
    <NumberFormat
      {...field.input}
      type={field.type}
      id={field.id}
      format={field.numberFormat}
      placeholder={field.placeholder}
      allowLeadingZeros={field.allowLeadingZeros}
    />
    {field.meta.touched &&
     field.meta.error &&
     <span className="error">{field.meta.error}</span>}
  </div>

class ChangePaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSubmittedForm: false,
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.handleNameFields = this.handleNameFields.bind(this);
  }

  handleNameFields(event) {
    // Don't allow numbers to be input into the name field. JS event.charCodes 48 through 57 are the 0 through 9 keys.
    if (event.charCode >= 48 && event.charCode <= 57) {
      event.preventDefault();
    }
  }

  handleUserSubmitForm() {
    this.setState(() => ({
      userSubmittedForm: true,
    }));
  }

  submitHandler() {
    const { userSubmittedForm } = this.state;
    const { onSubmitChangePaymentForm } = this.props;
    const event = this;

    if (event.props.submitFailed && userSubmittedForm) {
      this.setState(() => ({
        userSubmittedForm: false,
      }));
    }

    if (event.props.error) {
      throw new SubmissionError(event.props.error);
    } else {
      onSubmitChangePaymentForm(event);
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
    } = this.props;

    return (
      <div id="change-payment-form-container">
        <form
          id="change-payment-form"
          onSubmit={handleSubmit(this.submitHandler)}
          className="validate custom-form subscribe-form"
        >
          <div className="grid-x grid-padding-x">
            <div className="cell small-6">
              <label htmlFor="firstName" ref="firstName">
                <span className="red">*</span> First Name
                <Field
                  name="firstName"
                  component={renderInput}
                  onKeyPress={this.handleNameFields}
                  placeholder="First Name"
                  id="firstName"
                  type="text"
                />
              </label>
            </div>
            <div className="small-6 cell">
              <label htmlFor="lastName" ref="lastName">
                <span className="red">*</span> Last Name
                <Field
                  name="lastName"
                  component={renderInput}
                  onKeyPress={this.handleNameFields}
                  placeholder="Last Name"
                  id="lastName"
                  type="text"
                />
              </label>
            </div>
          </div>
          <div className="grid-x grid-padding-x">
            <div className="small-12 cell">
              <label htmlFor="creditCard" ref="creditCard">
                <span className="red">*</span> Card Number
                <Field
                  name="creditCard"
                  component={renderNumberFormat}
                  numberFormat="#### #### #### ####"
                  type="tel"
                  allowLeadingZeros
                  placeholder="Credit Card"
                  id="creditCard"
                />
              </label>
            </div>
          </div>
          <div className="grid-x grid-padding-x">
            <div className="small-6 cell">
              <label htmlFor="expMonth" ref="expMonth">
                <span className="red">*</span>Exp Month
                <Field
                  name="expMonth"
                  component={renderNumberFormat}
                  numberFormat="##"
                  type="tel"
                  placeholder="MM"
                  allowLeadingZeros
                  id="expMonth"
                />
              </label>
            </div>
            <div className="small-6 cell">
              <label htmlFor="expYear" ref="expYear">
                <span className="red">*</span> Exp Year
                <Field
                  name="expYear"
                  component={renderNumberFormat}
                  numberFormat="####"
                  type="tel"
                  placeholder="YYYY"
                  id="expYear"
                />
              </label>
            </div>
          </div>
          <div className="grid-x grid-padding-x">
            <div className="small-6 cell">
              <label htmlFor="cvc" ref="cvc">
                <span className="red">*</span> CVC
                <Field
                  name="cvc"
                  component={renderNumberFormat}
                  id="cvc"
                  type="tel"
                  placeholder="CVC"
                  allowLeadingZeros
                  id="cvc"
                />
              </label>
            </div>
            <div className="small-6 cell">
              <label htmlFor="zipCode" ref="zipCode">
                <span className="red">*</span> Billing Zip
                <Field
                  name="zipCode"
                  component={renderInput}
                  type="text"
                  minLength="5"
                  maxLength="7"
                  placeholder="Zip Code"
                  id="zipCode"
                />
              </label>
            </div>
          </div>
          <div className="grid-x grid-padding-x">
            <div className="small-12 cell">
              <button
                type="submit"
                onClick={() => this.handleUserSubmitForm}
                className="flat button button--notRounded u-width-full"
                disabled={submitting}
              >
                Update Payment Info
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ChangePaymentForm.propTypes = {
  ...propTypes, // Props from redux form
  handleSubmit: PropTypes.func.isRequired,
  onSubmitChangePaymentForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    trackingId: PropTypes.string,
    sip: PropTypes.shape({
      uri: PropTypes.string,
      password: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    totalNumberBurners: PropTypes.number,
    credits: PropTypes.number,
    version: PropTypes.number,
    id: PropTypes.string,
    dateCreated: PropTypes.number,
    carrierName: PropTypes.string,
    createdSample: PropTypes.bool,
    lastUpdatedDate: PropTypes.number,
    countryCode: PropTypes.string,
    superUser: PropTypes.bool,
    platform: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    lifetimeSubscriptions: PropTypes.number,
  }).isRequired,
};

ChangePaymentForm.defaultProps = {
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

ChangePaymentForm = reduxForm(
  {
    form: 'changePayment',
    validate: createValidator({
      firstName: [required],
      lastName: [required],
      creditCard: [required, validateCreditCard],
      expMonth: [required, validateExpMonth],
      expYear: [required, validateExpYear],
      cvc: [required, validateCvc],
      zipCode: [required, validateZip],
    }),
  },
)(ChangePaymentForm);

export default connect(
  mapStateToProps,
)(ChangePaymentForm);
