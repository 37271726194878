import React from 'react';
import PropTypes from 'prop-types';

import { calcPriceWithDiscount } from '../../utils/general';
import { renderLocalNumber } from '../../utils/phone';
import { toHumanDate } from '../../utils/date';
import AppButtons from '../../components/AppButtons';

const propTypes = {
  discountAmount: PropTypes.number,
  freeTrialLength: PropTypes.number,
  selectedNumber: PropTypes.string.isRequired,
  subscription: PropTypes.shape({
    totalConcurrentBurners: PropTypes.number,
    totalBurnersAllowedInPeriod: PropTypes.number,
    sku: PropTypes.string,
  }).isRequired,
  userEmail: PropTypes.string.isRequired,
};

const defaultProps = {
  discountAmount: 0,
  freeTrialLength: null,
};

const TileImg = require('images/tiles/tile-02-mobile.jpg');
const GoogleBadge = require('images/play-store-badge.png');
const AppleBadge = require('images/app-store-badge.png');
function ReceiptWithNumber({
  subscription,
  userEmail,
  discountAmount,
  freeTrialLength,
  selectedNumber,
}) {
  if (!subscription) {
    return null;
  }

  document.body.scrollTop = 1;

  return (
    <div className="TileContainer__wrapper grid-container full u-padding-top-5vh">
      <div className="grid-x align-center-middle ConfirmationBox__bg--tile">
        <div className="cell large-7 small-12 small-order-2 large-order-1 ConfirmationBox__bg--yellow">
          <div className="grid-x grid-padding-x u-padding-y-10vh u-bg-solid--brand align-center-middle">
            <div className="cell large-7 large-offset-3 small-11">
              <h1>All set!</h1>
              <h5 className="u-margin-top-35px">Your new Burner number is:</h5>
              <h3 className="BurnerNumber__highlight">
                {renderLocalNumber(selectedNumber)}
              </h3>
              <ul className="ConfirmationDetails__wrapper">
                <li>
                  A confirmation email as been sent to:{' '}
                  <strong>{userEmail}</strong>
                </li>
                <li>
                  Total Charged today:{' '}
                  <strong>
                    $
                    {freeTrialLength
                      ? '0.00'
                      : calcPriceWithDiscount(
                          subscription.price / 100,
                          discountAmount
                        )}
                  </strong>
                </li>
                <li>
                  Next charge:{' '}
                  <strong>
                    ${subscription.price / 100} on{' '}
                    {toHumanDate(subscription.renewalDate)}
                  </strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="grid-x grid-padding-x u-padding-y-10vh align-center-middle">
            <div className="cell large-7 large-offset-3 small-10">
              <h5><AppButtons /></h5>
            </div>
          </div>
        </div>

        <div className="cell large-5 small-12 small-order-1 large-order-2">
          <div className="grid-x align-right-middle">
            <div className="cell small-12">
              <img
                src={TileImg}
                className="show-for-small hide-for-large"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="FloatingDownloadButtons">
        <a href="https://app.adjust.com/gegg2hg?fallback=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&amp;utm_source=burnerapp&amp;utm_medium=app_store&amp;utm_campaign=confirmation-page&amp;redirect_macos=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&amp;utm_source=burnerapp&amp;utm_medium=app_store&amp;utm_campaign=confirmation-page">
          <img
            src={AppleBadge}
            loading="lazy"
            alt="Download on the App Store"
          />
        </a>
        <a href="https://app.adjust.com/9j9egjp?fallback=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&amp;utm_source=burnerapp&amp;utm_medium=play_store&amp;utm_campaign=confirmation-page&amp;redirect_macos=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&amp;utm_source=burnerapp&amp;utm_medium=play_store&amp;utm_campaign=confirmation-page">
          <img src={GoogleBadge} loading="lazy" alt="Get it on Google Play" />
        </a>
      </div>
    </div>
  );
}

ReceiptWithNumber.propTypes = propTypes;
ReceiptWithNumber.defaultProps = defaultProps;

export default ReceiptWithNumber;
