import { PURCHASED, CUSTOM_WEB_EVENT, GA_KEY } from '../constants.js';
import ReactGA from 'react-ga';

const SEPERATOR = '::';

ReactGA.initialize(GA_KEY);

function getQueryParams() {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const params = {};
  let match;
  while ((match = regex.exec(window.location.href))) {
    params[match[1]] = match[2];
  }
  return params;
}

function str(val) {
  return val ? val : '';
}

export function getReferrerToken() {
  const params = getQueryParams();
  let token = '';
  token +=
    str(params.utm_source) +
    SEPERATOR +
    str(params.utm_campaign) +
    SEPERATOR +
    str(params.utm_medium) +
    SEPERATOR +
    str(params.utm_content);
  token = token.replace(/:+$/, '');
  return token ? token : 'Organic';
}

export function logPurchase(subId, sku, price, subscriptionName, currentUser) {
  const userId = currentUser.id;

  dataLayer.push({ userId });
  dataLayer.push({
    event: CUSTOM_WEB_EVENT,
    customWebEventName: PURCHASED,
    customWebEventAction: sku,
    customWebEventLabel: price,
  });
  dataLayer.push({
    event: PURCHASED,
    sku,
    amount: price,
    ecommerce: {
      // needed for ecommerce integration for GA
      purchase: {
        actionField: {
          id: subId,
          revenue: price,
        },
        products: [
          {
            id: sku,
            name: subscriptionName,
            sku,
            category: 'Subscriptions',
            price,
            quantity: 1,
          },
        ],
      },
    },
  });
}

export function trackSitebuilderSendSms() {
  ReactGA.event({
    category: 'User',
    action: 'Clicked Sitebuilder Send A Text',
  });
}
