import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { OAUTH_REDIRECT_PARAMS, OAUTH_MESSAGES_CONNECT } from '../constants';
import * as actionCreators from '../ducks/oauth';
import * as authActions from '../ducks/auth';
import * as generalActions from '../ducks/general';
import { canCreateSample } from '../utils/createSample';
import { formatE164 } from '../utils/phone';
import Footer from '../components/oauth/Footer';
import OauthAwareButton from '../components/oauth/OauthAwareButton';
import OauthAwareHeader from '../components/oauth/OauthAwareHeader';
import '!style-loader!css-loader!sass-loader!../../styles/oauth.scss';
import '!style-loader!css-loader!sass-loader!../../styles/oauth-responsive.scss';

const propTypes = {
  authorize: PropTypes.func,
  authorizeCode: PropTypes.string,
  authorizeState: PropTypes.number,
  authToken: PropTypes.string,
  availableBurners: PropTypes.array,
  clientInfo: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    sampleSku: PropTypes.string,
    thumbnailURL: PropTypes.string,
  }),
  confirmedBurnerSelection: PropTypes.bool,
  fetchClientInfo: PropTypes.func,
  getBurnersList: PropTypes.func,
  getScopeDefinitions: PropTypes.func,
  humanFriendlyScopes: PropTypes.array,
  isAuthenticated: PropTypes.bool,
  location: PropTypes.object,
  selectBurnersForOauth: PropTypes.func,
  selectedBurnerIds: PropTypes.array,
  setRedirectPath: PropTypes.func,
  toggleBurnerSelect: PropTypes.func,
  user: PropTypes.shape({
    trackingId: PropTypes.string,
    sip: PropTypes.shape({
      uri: PropTypes.string,
      password: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    totalNumberBurners: PropTypes.number,
    credits: PropTypes.number,
    version: PropTypes.number,
    id: PropTypes.string,
    dateCreated: PropTypes.number,
    carrierName: PropTypes.string,
    createdSample: PropTypes.bool,
    lastUpdatedDate: PropTypes.number,
    countryCode: PropTypes.string,
    superUser: PropTypes.bool,
    platform: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    lifetimeSubscriptions: PropTypes.number,
  }),
};

class OauthPage extends Component {
  static PATH = '/oauth/authorize';

  constructor(props) {
    super(props);
    const { location } = this.props;
    const query = location.query;
    const clientId = query.client_id;
    const burnerId = query.burner_id;
    const phoneNumber = query.phone_number;
    this.state = {
      burnerId,
      clientId,
      phoneNumber,
    };

    this.setReturnMarker = this.setReturnMarker.bind(this);
    this.sendToChooseNumberFlow = this.sendToChooseNumberFlow.bind(this);
    this.sendToUnAuthenticatedFlow = this.sendToUnAuthenticatedFlow.bind(this);
    this.sendToVerifyPage = this.sendToVerifyPage.bind(this);
    this.sendToLoginPage = this.sendToLoginPage.bind(this);
    this.redirectIfAuthSuccessful = this.redirectIfAuthSuccessful.bind(this);
    this.maybeLookupScope = this.maybeLookupScope.bind(this);
    this.checkMissingParams = this.checkMissingParams.bind(this);
    this.createHeadingText = this.createHeadingText.bind(this);
    this.createDescriptionText = this.createDescriptionText.bind(this);
    this.createActionButton = this.createActionButton.bind(this);
    this.ensureBurnerExistsOrError = this.ensureBurnerExistsOrError.bind(this);
    this.sendToErrorPage = this.sendToErrorPage.bind(this);
    this.hasBurnerSpecificScope = this.hasBurnerSpecificScope.bind(this);
    this.maybeRenderBurnerPicker = this.maybeRenderBurnerPicker.bind(this);
    this.maybeRenderAuthSection = this.maybeRenderAuthSection.bind(this);
    this.renderHumanFriendlyScopes = this.renderHumanFriendlyScopes.bind(this);
    this.renderSelectBurnerButtons = this.renderSelectBurnerButtons.bind(this);
  }

  componentDidMount() {
    const { burnerId, clientId } = this.state;
    const {
      user,
      authToken,
      availableBurners,
      clientInfo,
      selectBurnersForOauth,
      getBurnersList,
      fetchClientInfo,
    } = this.props;

    this.checkMissingParams();

    if (burnerId) {
      selectBurnersForOauth(burnerId);
    } else if (user && availableBurners === undefined) {
      // no previously selected burner from the oauth flow, show list of burners
      getBurnersList({ authToken, userId: user.id });
    }

    if (!clientInfo) {
      fetchClientInfo(clientId, () => this.sendToLoginPage(clientId));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { user, isAuthenticated } = this.props;

    if (!isAuthenticated || !user) {
      this.sendToUnAuthenticatedFlow();
      return;
    } else if (canCreateSample(nextProps)) {
      this.sendToChooseNumberFlow();
      return;
    }
    //do a lookup from the scope keys to human friendly scopes
    this.maybeLookupScope(nextProps);
    // if auth successful, redirect to the uri defined
    this.redirectIfAuthSuccessful(nextProps);

    const { selectedBurnerIds, selectBurnersForOauth } = this.props;
    const burnerId = selectedBurnerIds[0]; // we are only checking the first id since that's the one that comes from the query params

    if (
      nextProps.availableBurners &&
      !burnerId &&
      nextProps.availableBurners.length === 1
    ) {
      // if there's only 1 burner, then just select it)
      selectBurnersForOauth(nextProps.availableBurners[0].id);
    }
    if (nextProps.availableBurners && burnerId) {
      this.ensureBurnerExistsOrError(nextProps, burnerId);
    }
  }

  // save the original query params so after auth it redirects back to this page with the orig params.
  setReturnMarker() {
    const { location } = this.props;

    localStorage.setItem(OAUTH_REDIRECT_PARAMS, location.search);
  }

  sendToChooseNumberFlow() {
    const { clientId } = this.state;
    const { setRedirectPath } = this.props;
    const { router } = this.context;

    setRedirectPath(OauthPage.PATH);
    this.setReturnMarker();
    router.push(`/chooseNumber?utm_source=${clientId}&utm_medium=partner&utm_campaign=listing`);
  }

  sendToUnAuthenticatedFlow() {
    const { phoneNumber, clientId } = this.state;

    this.setReturnMarker();
    if (phoneNumber) {
      this.sendToVerifyPage(formatE164(phoneNumber));
    } else {
      this.sendToLoginPage(clientId);
    }
  }

  sendToVerifyPage(phoneNumber) {
    const { clientId } = this.state;
    const { setRedirectPath } = this.props;
    const { router } = this.context;

    setRedirectPath(OauthPage.PATH);
    router.push(`/verify/${phoneNumber}&utm_source=${clientId}&utm_medium=partner&utm_campaign=listing`);
  }

  sendToLoginPage(clientId) {
    const { setRedirectPath } = this.props;
    const { router } = this.context;

    setRedirectPath(OauthPage.PATH);
    router.push(`/?client_id=${clientId}&utm_source=${clientId}&utm_medium=partner&utm_campaign=listing`);
  }

  redirectIfAuthSuccessful(nextProps) {
    const { location } = this.props;

    if (nextProps.authorizeCode) {
      document.location = `${location.query.redirect_uri}?code=${
        nextProps.authorizeCode
      }&state=${nextProps.authorizeState}`;
    }
  }

  maybeLookupScope(nextProps) {
    const {
      humanFriendlyScopes,
      authToken,
      user,
      getScopeDefinitions,
      location,
    } = this.props;

    if (
      (!this.hasBurnerSpecificScope() || nextProps.confirmedBurnerSelection) &&
      !humanFriendlyScopes
    ) {
      getScopeDefinitions(
        authToken,
        user.id,
        location.query.scope,
        nextProps.selectedBurnerIds
      );
    }
  }

  checkMissingParams() {
    const { location } = this.props;
    const query = location.query;
    const clientId = query.client_id;
    const scope = query.scope;
    const redirectUri = query.redirect_uri;
    const { router } = this.context;

    if (!redirectUri) {
      router.push(`/oauth/error?error=redirect_uri must be set&utm_source=${clientId}&utm_medium=partner&utm_campaign=listing`);
    } else if (!clientId) {
      this.sendToErrorPage('Sorry, field client_id is not found or is empty.');
    } else if (!scope) {
      this.sendToErrorPage('Sorry, field scope is not found or is empty.');
    }
  }

  createHeadingText() {
    const { availableBurners, clientInfo } = this.props;

    if (availableBurners.length > 0) {
      return (
        <h4>
          Choose Burner(s) to connect to{' '}
          <span className="highlight-red">{clientInfo.name}</span>
        </h4>
      );
    }

    return (
      <h4>
        Hmm. No Burners to connect to{' '}
        <span className="highlight-red">{clientInfo.name}</span>.
      </h4>
    );
  }

  createDescriptionText() {
    const { availableBurners } = this.props;

    if (availableBurners.length > 0) {
      return (
        <p>
          You can manage permissions for all third party apps under Authorized
          Apps in Burner.
        </p>
      );
    }

    return (
      <p>
        This app is requesting permission to listen or send messages from your
        Burners, but you have no active Burners. Please create a new Burner from
        the Burner app then come back to this page and refresh.
      </p>
    );
  }

  createActionButton() {
    const {
      clientInfo,
      availableBurners,
      selectedBurnerIds,
      selectBurnersForOauth,
    } = this.props;

    if (availableBurners.length > 0) {
      return (
        <OauthAwareButton
          clientInfo={clientInfo}
          disabled={!selectedBurnerIds || selectedBurnerIds.length === 0}
          onClick={() => {
            selectBurnersForOauth();
          }}
          className="flat button submit"
          buttonText="Next"
        />
      );
    }

    return (
      <OauthAwareButton
        clientInfo={clientInfo}
        onClick={() => {
          this.sendToErrorPage('User does not have any Burners');
        }}
        className="flat button submit"
        buttonText="Close"
      />
    );
  }

  ensureBurnerExistsOrError(nextProps, burnerId) {
    if (
      nextProps.availableBurners.findIndex(
        (burner) => burner.id === burnerId
      ) === -1
    ) {
      this.sendToErrorPage('Field burnerId is not valid.');
    }
  }

  sendToErrorPage(reason) {
    const { location } = this.props;
    const errorUri = `${location.query.redirect_uri}?error=${reason}`;
    document.location = errorUri;
  }

  hasBurnerSpecificScope() {
    const { location } = this.props;
    const scope = location.query.scope;
    return scope.includes(OAUTH_MESSAGES_CONNECT);
  }

  maybeRenderBurnerPicker() {
    const {
      availableBurners,
      confirmedBurnerSelection,
      clientInfo,
      location,
    } = this.props;
    const burnerId = location.query.burner_id;
    const shouldShowBurnerPicker =
      this.hasBurnerSpecificScope() &&
      !burnerId &&
      availableBurners &&
      !confirmedBurnerSelection;

    if (shouldShowBurnerPicker && clientInfo) {
      return (
        <div>
          <div className="heading">{this.createHeadingText()}</div>
          {this.renderSelectBurnerButtons(availableBurners)}

          <div className="description">{this.createDescriptionText()}</div>
          <div className="row">
            <div className="columns submit-container">
              {this.createActionButton()}
            </div>
          </div>
        </div>
      );
    }
  }

  maybeRenderAuthSection() {
    const { location } = this.props;
    const query = location.query;
    const clientId = query.client_id;
    const state = query.state;
    const scope = query.scope;
    const redirectUri = query.redirect_uri;
    const {
      clientInfo,
      selectedBurnerIds,
      confirmedBurnerSelection,
      authorize,
      authToken,
      user,
    } = this.props;

    const chosenBurner =
      selectedBurnerIds.length > 0 && confirmedBurnerSelection;
    if (clientInfo && (!this.hasBurnerSpecificScope() || chosenBurner)) {
      return (
        <div>
          <div className="heading">
            <h4>
              <span>{clientInfo.name}</span> will be granted the following
              permissions
            </h4>
          </div>
          {this.renderHumanFriendlyScopes()}
          <div className="row">
            <div className="small-12 medium-12 large-12 columns policyLinks">
              You can revoke these permissions at any time in the Authorized
              Apps menu in the Burner app.
            </div>
          </div>
          <div className="row button-container">
            <div className="small-12 medium-12 large-6 large-push-6 columns">
              <OauthAwareButton
                onClick={() => {
                  authorize(
                    authToken,
                    user.id,
                    clientId,
                    state,
                    scope,
                    redirectUri,
                    selectedBurnerIds,
                    () => {
                      this.sendToLoginPage(clientId);
                    }
                  );
                }}
                clientInfo={clientInfo}
                className="flat button submit full-width"
                buttonText="Authorize"
              />
            </div>
            <div className="small-12 medium-12 large-6 large-pull-6 columns">
              <button
                type="button"
                onClick={() => {
                  this.sendToErrorPage('User Cancelled');
                }}
                className="button submit flat full-width cancel-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  renderHumanFriendlyScopes() {
    const { humanFriendlyScopes } = this.props;
    let html;

    if (humanFriendlyScopes) {
      html = humanFriendlyScopes.map((scope, index) => (
        <div key={index}>
          <p>
            <span className="scope-message" dangerouslySetInnerHTML={scope} />
          </p>
        </div>
      ));
    }

    return (
      <div className="scope-messages large-8 large-offset-2 medium-8 medium-offset-2 small-10 small-offset-1">
        {html}
      </div>
    );
  }

  renderSelectBurnerButtons(availableBurners) {
    const { toggleBurnerSelect, selectedBurnerIds } = this.props;

    const html = availableBurners.map((burner, index) => (
      <div
        key={index}
        className="columns small-10 small-offset-1 large-6 large-offset-0 oauth-burner-select-container"
      >
        <button
          type="button"
          onClick={() => {
            toggleBurnerSelect(burner);
          }}
          className={
            selectedBurnerIds.includes(burner.id)
              ? 'button burner-select-btn full-width selected'
              : 'button burner-select-btn full-width'
          }
        >
          {burner.name}
        </button>
      </div>
    ));

    return <div className="row burner-select-container">{html}</div>;
  }

  render() {
    const { clientInfo } = this.props;

    return (
      <div className="row main-app">
        <div className="large-10 large-offset-1 medium-8 medium-offset-2 columns">
          <div className="root-container" id="oauth-container">
            <OauthAwareHeader clientInfo={clientInfo} />
            <div>
              {this.maybeRenderBurnerPicker()}
              {this.maybeRenderAuthSection()}

              <div className="oauth-bottom-container">
                <Footer clientInfo={clientInfo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OauthPage.propTypes = propTypes;
OauthPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authorizeCode: state.oauthPage.authorizeCode,
    authorizeState: state.oauthPage.authorizeState,
    authToken: state.auth.authToken,
    availableBurners: state.oauthPage.availableBurners,
    clientInfo: state.oauthPage.clientInfo,
    confirmedBurnerSelection: state.oauthPage.confirmedBurnerSelection,
    humanFriendlyScopes: state.oauthPage.humanFriendlyScopes,
    isAuthenticated: state.auth.isAuthenticated,
    selectedBurnerIds: state.oauthPage.selectedBurnerIds,
    user: state.auth.user,
  };
}

const mapDispatchToProps = {
  ...actionCreators,
  ...generalActions,
  ...authActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OauthPage);
