import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  buttonText: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  clientInfo: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    sampleSku: PropTypes.string,
    thumbnailURL: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

/**
 * Button that changes colors depending on whether the oauth client has a color defined
 */
function OauthAwareButton({ clientInfo = null, disabled = false, onClick = () => {}, buttonText, className }) {
  let style = {};

  if (clientInfo && clientInfo.color) {
    style = { backgroundColor: clientInfo.color };
  }

  return (
    <button disabled={disabled} onClick={onClick} style={style} className={className}>
      {buttonText}
    </button>
  );
}

OauthAwareButton.propTypes = propTypes;

export default OauthAwareButton;
