export const CLEAR_ERRORS = 'burner-web/error/CLEAR_ERRORS';
export const ERROR = 'burner-web/error/ERROR';
export const VERIFY_CODE_ERROR = 'burner-app/error/VERIFY_CODE_ERROR';
export const RESET_VERIFY_CODE_ERROR = 'burner-app/error/RESET_VERIFY_CODE_ERROR';

export default function reducer(
  state = {
    isUnauthorized: false,
    redirectBackAction: null,
  },
  action
) {
  switch (action.type) {
    case ERROR:
      return Object.assign({}, state, {
        errorMessage: action.error,
        isUnauthorized: action.isUnauthorized,
        shouldLog: action.shouldLog,
        goBack: action.goBack,
        redirectBackAction: action.redirectBackAction,
      });
    case CLEAR_ERRORS:
      return Object.assign({}, state, {
        errorMessage: null,
        isUnauthorized: false,
        redirectBackAction: null,
      });
    default:
      return state;
  }
}

export function clearErrors() {
  return {
    type: CLEAR_ERRORS,
  };
}

// called when the ajax call has an error
export function hasError(error, statusCode, shouldLog, redirectBackAction) {
  return {
    type: ERROR,
    error,
    isUnauthorized: statusCode === 401, // 401 mean unauthorized. log user out
    shouldLog: !!shouldLog,
    redirectBackAction,
  };
}

export function validationCodeError(error) {
  return {
    type: VERIFY_CODE_ERROR,
    error,
  };
}

export function resetVerifyCodeError() {
  return {
    type: RESET_VERIFY_CODE_ERROR,
  };
}
