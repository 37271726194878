import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { getHumanScopeText } from '../../constants';
import * as actionCreators from '../../ducks/applications';

const propTypes = {
  authorizations: PropTypes.string,
  showConfirmRevokeModal: PropTypes.func.isRequired,
};

const defaultProps = {
  authorizations: null,
};

class AuthorizedApps extends Component {
  renderScopes(scopes) {
    return scopes.map((scope, index) => (
      <div key={index}>
        <li className="scope" dangerouslySetInnerHTML={getHumanScopeText(scope, ['your active Burners'])} />
      </div>
    ));
  }

  renderNoAuthsMessage() {
    return (
      <h5 className="no-auths-heading">
        You don&apos;t have any authorized Applications, add an application from the link above now!
      </h5>
    );
  }

  render() {
    const { authorizations, showConfirmRevokeModal } = this.props;
    let list;

    if (authorizations) {
      list = authorizations.map((authorization, index) => (
        <tr key={index}>
          <td className="application-td">
            <h6>
              <a>{authorization.clientName}</a>
            </h6>
          </td>
          <td>
            <ul>{this.renderScopes(authorization.scopes)}</ul>
          </td>
          <td>{authorization.burners.map((val) => val.name).join(', ')}</td>
          <td>{moment(authorization.dateCreated).format('MMMM Do YYYY')}</td>
          <td className="buttons">
            <button className="button" onClick={() => showConfirmRevokeModal(authorization)} type="button">
              <i className="fa fa-trash" />
            </button>
          </td>
        </tr>
      ));
    } else {
      list = null;
    }

    return (
      <div>
        <table className="responsive">
          <thead>
            <tr>
              <th>Application</th>
              <th>Permissions</th>
              <th>Burners</th>
              <th>Date Added</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{list}</tbody>
        </table>

        {list && list.length === 0 ? this.renderNoAuthsMessage() : ''}
      </div>
    );
  }
}

AuthorizedApps.propTypes = propTypes;
AuthorizedApps.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    authorizations: state.applicationsPage.authorizations,
  };
}

const mapDispatchToProps = actionCreators;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizedApps);
