import axios from 'axios';
import cookie from 'react-cookie';
import {
  AUTHENTICATION_KEY,
  CURRENT_USER,
  BASE_URL,
  V2_BASE_URL,
  IS_SECURED,
  DEFAULT_SKU,
  CUSTOM_WEB_EVENT,
  SUBMIT_PAYMENT_INFO,
} from '../constants';
import { RECEIVE_PHONE_NUMBERS } from './chooseNumber';
import { fetchVerifyCode, SUBSCRIPTION_CREATED, RECEIVE_CODE, SUBSCRIBE_MODAL_TOGGLED } from './verify';
import { hasError, validationCodeError, VERIFY_CODE_ERROR, RESET_VERIFY_CODE_ERROR } from './error';
import { requesting } from './general';
import { getCountry } from '../utils/phone';
import { getCurrentUser } from '../utils/user';
import { extractError } from '../utils/request';

export const RESET_PHONE_NUMBER_SEARCH = 'burner-app/subscribe/RESET_PHONE_NUMBER_SEARCH';
export const SET_SUBSCRIPTION_PLAN = 'burner-app/subscribe/SET_SUBSCRIPTION_PLAN';
export const SET_SELECTED_NUMBER = 'burner-app/subscribe/SET_SELECTED_NUMBER';
export const NUMBER_IS_TAKEN = 'burner-app/subscribe/NUMBER_IS_TAKEN';
export const NUMBER_ASSOCIATED = 'burner-app/subscribe/NUMBER_ASSOCIATED';
export const PHONE_DOES_NOT_EXIST = 'Could not find a phone number.';

export default function reducer(state = { sku: 'com.adhoclabs.burner.subscription.month.1' }, action) {
  switch (action.type) {
    case SUBSCRIPTION_CREATED:
      return Object.assign({}, state, {
        subscription: action.response,
        userEmail: action.email,
        accountPhoneNumber: action.accountPhoneNumber,
      });
    case RECEIVE_CODE:
      return Object.assign({}, state, {
        sku: action.sku || DEFAULT_SKU, // needed for subscribe page
      });
    case RECEIVE_PHONE_NUMBERS:
      return Object.assign({}, state, {
        numbers: action.numbers,
      });
    case RESET_PHONE_NUMBER_SEARCH:
      return Object.assign({}, state, {
        numbers: null,
        selectedSku: null,
        numberAssociated: false,
        selectedNumber: null,
      });
    case SET_SELECTED_NUMBER:
      return Object.assign({}, state, {
        selectedNumber: action.selectedNumber,
      });
    case NUMBER_IS_TAKEN:
      return Object.assign({}, state, {
        numbers: state.numbers.filter((item) => item.phoneNumber !== state.selectedNumber),
        selectedNumber: null,
        isNumberTaken: true,
      });
    case VERIFY_CODE_ERROR:
      return Object.assign({}, state, {
        verifyErrorMessage: action.error,
      });
    case RESET_VERIFY_CODE_ERROR:
      return Object.assign({}, state, {
        verifyErrorMessage: null,
      });
    case NUMBER_ASSOCIATED:
      return Object.assign({}, state, {
        numberAssociated: true,
        selectedNumber: action.selectedNumber,
      });
    case SET_SUBSCRIPTION_PLAN:
      return Object.assign({}, state, {
        selectedSku: action.sku,
        price: action.price,
        numLines: action.numLines,
        freeTrialLength: action.freeTrialLength,
        isYearly: action.isYearly,
      });
    default:
      return state;
  }
}

export function selectSubscriptionPlan(plan) {
  return {
    type: SET_SUBSCRIPTION_PLAN,
    sku: plan.sku,
    price: plan.price,
    numLines: plan.numLines,
    freeTrialLength: plan.freeTrialLength,
    isYearly: plan.isYearly,
  };
}

export function setSelectedNumber(selectedNumber) {
  return {
    type: SET_SELECTED_NUMBER,
    selectedNumber,
  };
}

/**
 * Dispatches an event to state that the verify modal has toggled on, so it doesn't toggle again (unless user closes it).
 */
export function modalToggled() {
  return {
    type: SUBSCRIBE_MODAL_TOGGLED,
  };
}

export function resetSearch() {
  return {
    type: RESET_PHONE_NUMBER_SEARCH,
  };
}

function numberIsTaken() {
  return {
    type: NUMBER_IS_TAKEN,
  };
}

function numberAssociated(selectedNumber) {
  return {
    type: NUMBER_ASSOCIATED,
    selectedNumber,
  };
}

export function associateSelectedNumberWithSub(selectedNumber, subscription) {
  const userId = getCurrentUser().id;
  const authToken = cookie.load(AUTHENTICATION_KEY);

  return (dispatch) => {
    dispatch(requesting());
    return axios
      .post(
        `${V2_BASE_URL}/user/${userId}/burners`,
        {
          phoneNumber: selectedNumber,
          subscriptionId: subscription.id,
          useSip: false,
        },
        {
          headers: {
            Authentication: authToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(() => {
        dispatch(numberAssociated(selectedNumber));
      })
      .catch((error) => {
        if (error.data && error.data.error && PHONE_DOES_NOT_EXIST === error.data.error) {
          dispatch(numberIsTaken());
        } else {
          dispatch(hasError(extractError(error), error.status));
        }
      });
  };
}

/**
 * Will try to associate a phone number to a user's account. Happens when the initial number failed to be associated with the burner.
 */
export function reprovisionNumber(selectedNumber, subscription) {
  return (dispatch) => {
    dispatch(setSelectedNumber(selectedNumber));
    return dispatch(associateSelectedNumberWithSub(selectedNumber, subscription));
  };
}

function handleSubscriptionCreated(email, accountPhoneNumber, response) {
  return {
    type: SUBSCRIPTION_CREATED,
    accountPhoneNumber,
    email,
    response,
  };
}

export function createSubscription(formData, stripeResponse) {
  const userId = getCurrentUser().id;
  const authToken = cookie.load(AUTHENTICATION_KEY);
  const body = {
    token: stripeResponse.id,
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    sku: formData.sku,
  };

  return (dispatch) => {
    dispatch(requesting());
    return axios
      .post(`${BASE_URL}/user/${userId}/subscriptions/stripe`, body, {
        headers: {
          Authentication: authToken,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        dispatch(handleSubscriptionCreated(formData.email, formData.phoneNumber, resp.data));
        if (formData.selectedNumber) {
          // if the user has chosen a number, then also make a call to associate the number with the new sub
          dispatch(associateSelectedNumberWithSub(formData.selectedNumber, resp.data));
        }
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status));
      });
  };
}

export function getStripeAuthToken(payload) {
  return new Promise((resolve, reject) => {
    Stripe.card.createToken(payload, (statusCode, response) => {
      if (statusCode === 200) {
        resolve(response);
      } else {
        reject(response.error);
      }
    });
  });
}

function handleStripeSubmit(formData) {
  return (dispatch) => {
    dispatch(requesting());

    const stripePayload = {
      name: `${formData.firstName} ${formData.lastName}`,
      number: formData.number,
      expMonth: formData.expMonth,
      expYear: formData.expYear,
      cvc: formData.cvc,
      address_zip: formData.zipCode,
    };

    return getStripeAuthToken(stripePayload)
      .then((response) => dispatch(createSubscription(formData, response)))
      .catch((error) => dispatch(hasError(extractError(error))));
  };
}

function hasSubscriptionCheck(payload) {
  return (dispatch) => {
    dispatch(requesting());

    return axios
      .get(`${BASE_URL}/user/${getCurrentUser().id}/subscriptions`, {
        headers: {
          Authentication: cookie.load(AUTHENTICATION_KEY),
        },
      })
      .then((resp) => {
        if (resp.data.length > 0) {
          dataLayer.push({
            event: CUSTOM_WEB_EVENT,
            customWebEventName: SUBMIT_PAYMENT_INFO,
            customWebEventAction: 'failed',
            customWebEventLabel: 'already subscribed',
          });
          dispatch(
            hasError(
              'You already have a subscription. Your credit card was not charged. Please log in to the app to start using Burner!'
            )
          );
        } else {
          dispatch(handleStripeSubmit(payload));
        }
      })
      .catch((error) => dispatch(hasError(extractError(error), error.status)));
  };
}

/**
 * Handles when a user clicks on the handle verify submit. Will call backend to get the user and token, then stripe to get auth token. Finally, will call backend with the token
 */
export function validateVerifyCode(payload, pin) {
  return (dispatch) => {
    dispatch(requesting());
    return axios
      .post(
        `${BASE_URL}/register`,
        {
          country: getCountry(payload.phoneNumber),
          phoneNumber: payload.phoneNumber,
          code: pin,
          trackingId: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((resp) => {
        const authToken = resp.headers.authentication;
        cookie.save(AUTHENTICATION_KEY, authToken, { path: '/', secure: IS_SECURED });
        cookie.save(CURRENT_USER, resp.data, { path: '/', secure: IS_SECURED });
        // check to see if user has a subscription already
        dispatch(hasSubscriptionCheck(payload));
      })
      .catch((error) => dispatch(validationCodeError(extractError(error))));
  };
}

/**
 * Handles when a user clicks on the submit. Will call the backend for VC
 * validation, then if that's successful will pop up the verify code modal
 */
export function handleSubmit(phoneNumber, sku) {
  return fetchVerifyCode(phoneNumber, false, sku);
}

/**
 * Handles a resend verify code action
 */
export function resendVerifyCode(number) {
  return fetchVerifyCode(number, true);
}

function receiveNumbers(numbers) {
  return {
    type: RECEIVE_PHONE_NUMBERS,
    numbers,
  };
}

export function requestNumber(areaCode) {
  return (dispatch) => {
    dispatch(requesting());

    // gets the user first, then gets the list of burners for that user
    return axios
      .get(`${V2_BASE_URL}/availableNumbers?areaCode=${areaCode}`, {})
      .then((resp) => {
        dispatch(receiveNumbers(resp.data));
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status, false, '/areaCode'));
      });
  };
}

export function requestAndSelectNumber(areaCode) {
  return (dispatch) => {
    dispatch(requesting());

    // gets the available numbers then selects one
    return axios
      .get(`${V2_BASE_URL}/availableNumbers?areaCode=${areaCode}`, {})
      .then((resp) => {
        dispatch(receiveNumbers(resp.data));
        dispatch(setSelectedNumber(resp.data[0].phoneNumber));
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status, false, '/areaCode'));
      });
  };
}
