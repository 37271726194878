import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { STRIPE_KEY } from '../constants';
import * as actionCreators from '../ducks/subscribe';
import { formatE164 } from '../utils/phone';
import PurchasePage from '../pages/PurchasePage';

const propTypes = {
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  numberAssociated: PropTypes.bool,
  selectedNumber: PropTypes.string.isRequired,
  selectedSku: PropTypes.string.isRequired,
  subscription: PropTypes.shape({
    totalConcurrentBurners: PropTypes.number,
    totalBurnersAllowedInPeriod: PropTypes.number,
    sku: PropTypes.string,
  }),
};

const defaultProps = {
  numberAssociated: false,
  subscription: null,
};

class PurchasePageContainer extends Component {
  constructor(props) {
    super(props);

    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  componentDidMount() {
    Stripe.setPublishableKey(STRIPE_KEY);
  }

  componentDidUpdate() {
    const { subscription, numberAssociated } = this.props;
    const { router } = this.context;

    if (subscription && numberAssociated) {
      router.push('/confirmation');
    }
  }

  handleSubmitForm() {
    const { form, handleSubmit, selectedSku } = this.props;

    handleSubmit(formatE164(form.subscribe.values.phoneNumber), selectedSku);
  }

  render() {
    const cellPhone = require('images/subscribe/concept2/cell-phone_static.png');
    const { selectedNumber } = this.props;

    return (
      <PurchasePage
        handleSubmitForm={this.handleSubmitForm}
        selectedNumber={selectedNumber}
        cellPhone={cellPhone}
      />
    );
  }
}

PurchasePageContainer.propTypes = propTypes;
PurchasePageContainer.defaultProps = defaultProps;
PurchasePageContainer.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = function (state) {
  return {
    form: state.form,
    numberAssociated: state.subscribePage.numberAssociated,
    selectedNumber: state.subscribePage.selectedNumber,
    selectedSku: state.subscribePage.selectedSku,
    subscription: state.subscribePage.subscription,
  }
};

const mapDispatchToProps = actionCreators;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchasePageContainer);
