import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

import {
  OAUTH_REDIRECT_PARAMS,
  NUX_NUMBER_SELECTION_WEB,
  SIGNUP,
  CUSTOM_WEB_EVENT,
} from '../constants';
import * as actionCreators from '../ducks/chooseNumber';
import * as authActions from '../ducks/auth';
import { formatLocal } from '../utils/phone';
import AvailableNumberList from '../components/AvailableNumberList';
import Footer from '../components/oauth/Footer';
import OauthAwareButton from '../components/oauth/OauthAwareButton';
import Header from '../components/common/Header';
import OauthPage from '../pages/OauthPage';
import '!style-loader!css-loader!sass-loader!../../styles/subscription-rebrand.scss';

const propTypes = {
  areaCode: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  chosenNumber: PropTypes.func.isRequired,
  clientInfo: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    sampleSku: PropTypes.string,
    thumbnailURL: PropTypes.string,
  }).isRequired,
  createBurner: PropTypes.func.isRequired,
  isCreatedNumber: PropTypes.bool.isRequired,
  maxResultsCount: PropTypes.number.isRequired,
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      phoneNumber: PropTypes.string,
      state: PropTypes.string,
      rateCenter: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  redirectTo: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  sampleCreated: PropTypes.func.isRequired,
  searchAreaCode: PropTypes.func.isRequired,
  selectedNumber: PropTypes.string.isRequired,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      price: PropTypes.number,
      store: PropTypes.string,
      burnersAssignedInPeriod: PropTypes.number,
      id: PropTypes.string,
      receipt: PropTypes.string,
      renewalDate: PropTypes.number,
      creationDate: PropTypes.number,
      burnerIds: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  user: PropTypes.shape({
    trackingId: PropTypes.string,
    sip: PropTypes.shape({
      uri: PropTypes.string,
      password: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    totalNumberBurners: PropTypes.number,
    credits: PropTypes.number,
    version: PropTypes.number,
    id: PropTypes.string,
    dateCreated: PropTypes.number,
    carrierName: PropTypes.string,
    createdSample: PropTypes.bool,
    lastUpdatedDate: PropTypes.number,
    countryCode: PropTypes.string,
    superUser: PropTypes.bool,
    platform: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    lifetimeSubscriptions: PropTypes.number,
  }).isRequired,
};

class ChooseNumberPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaCodeInput: '',
      burnerName: '',
      errorMessage: '',
    };

    this.getTitle = this.getTitle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderAreaCodeChanged = this.renderAreaCodeChanged.bind(this);
    this.renderBurnerNameChanged = this.renderBurnerNameChanged.bind(this);
    this.renderAvailableNumberList = this.renderAvailableNumberList.bind(this);
    this.renderChooseBurnerName = this.renderChooseBurnerName.bind(this);
    this.renderErrorSection = this.renderErrorSection.bind(this);
    this.renderPickAreaCode = this.renderPickAreaCode.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { reset, sampleCreated } = this.props;
    const { router } = this.context;

    if (nextProps.isCreatedNumber === true) {
      reset(); // resets the state of this page so if user wants to create another burner, it starts from the beginning
      sampleCreated();

      const oauthParams = localStorage.getItem(OAUTH_REDIRECT_PARAMS);
      if (oauthParams && nextProps.redirectTo === OauthPage.PATH) {
        // for oauth there are query params that need to be appended back after successful choose number
        localStorage.removeItem(OAUTH_REDIRECT_PARAMS);
        router.push(this.buildRedirectUri(nextProps, oauthParams));
        return;
      }

      router.push('/dashboard');
      dataLayer.push({ event: CUSTOM_WEB_EVENT, customWebEventName: SIGNUP });
    }
  }

  getTitle() {
    const { clientInfo } = this.props;

    if (clientInfo) {
      return 'Choose an area code for your new phone number';
    }
    return 'Choose an area code';
  }

  buildRedirectUri(nextProps, oauthParams) {
    return `${nextProps.redirectTo}${oauthParams}&burner_id=${nextProps.burner.id}`;
  }

  createBurner(selectedNumber) {
    const { burnerName } = this.state;
    const { authToken, user, createBurner, subscriptions, areaCode } =
      this.props;
    const { clientInfo } = this.props;

    dataLayer.push({
      event: CUSTOM_WEB_EVENT,
      customWebEventName: NUX_NUMBER_SELECTION_WEB,
      customWebEventAction: areaCode,
    });

    if (user.createdSample && subscriptions && subscriptions[0]) {
      createBurner(
        authToken,
        user.id,
        burnerName,
        selectedNumber,
        subscriptions[0].id
      );
    } else {
      createBurner(
        authToken,
        user.id,
        burnerName,
        selectedNumber,
        null,
        clientInfo ? clientInfo.sampleSku : null
      );
    }
  }

  handleSubmit() {
    const { areaCodeInput } = this.state;
    const { authToken, user, searchAreaCode } = this.props;

    if (!areaCodeInput || areaCodeInput.length !== 3) {
      this.setState(() => ({
        errorMessage: 'Area code must be 3 digits.',
      }));
      return;
    }
    searchAreaCode(authToken, user.id, areaCodeInput);
  }

  renderAreaCodeChanged(values) {
    this.setState(() => ({
      areaCodeInput: values.value,
      errorMessage: null,
    }));
  }

  renderBurnerNameChanged(event) {
    const { value } = event.target;

    this.setState(() => ({
      burnerName: value,
      errorMessage: null,
    }));
  }

  renderAvailableNumberList() {
    const { numbers, chosenNumber, areaCode, clientInfo, maxResultsCount } =
      this.props;

    if (!numbers) {
      return null;
    }

    return (
      <div className="cell large-8 small-10">
        <div className="text-center number-picker">
          <h1>Choose a phone number</h1>
          <AvailableNumberList
            clientInfo={clientInfo}
            chosenAreaCode={areaCode}
            numbers={numbers}
            maxResultsCount={maxResultsCount}
            onChosenNumber={chosenNumber}
          />
        </div>
      </div>
    );
  }

  /**
   * Will render the input box for user to input the burner name after the user chosen the burner number.
   */
  renderChooseBurnerName() {
    const { burnerName } = this.state;
    const { selectedNumber } = this.props;

    if (!selectedNumber) {
      return null;
    }

    return (
      <div className="text-center cell large-10 small-10">
        <h1>Name your Burner</h1>
        <h2 className="desc">
          Number:{' '}
          <span className="SubscriptionNumber__highlight">
            {formatLocal(selectedNumber)}
          </span>
        </h2>
        <div className="u-margin-top-35px grid-x grid-padding-x align-center-middle">
          <div className="cell large-5 small-12">
            <div className="align-bottom Form__inputWrapper u-margin-top-35px grid-x grid-padding-x align-center">
              <label
                htmlFor="AreaCode"
                className="text-left Form__fieldWrapper cell auto"
              >
                <span className="Input__label">Burner name</span>

                <input
                  type="text"
                  required
                  className="Input__field"
                  onChange={this.renderBurnerNameChanged}
                  placeholder="Burner name"
                />
              </label>
              <div className="Form__submitWrapper cell shrink">
                <OauthAwareButton
                  disabled={!burnerName}
                  className="button button--notRounded"
                  buttonText="Create my number"
                  onClick={() => this.createBurner(selectedNumber)}
                />
              </div>
            </div>
            <div className="text-center Form__outputWrapper u-margin-top-35px grid-x align-center">
              <div className="cell large-8">
                <em>We currently support US and Canadian numbers</em>
              </div>
            </div>
            {this.renderErrorSection()}
          </div>
        </div>
      </div>
    );
  }

  renderErrorSection() {
    const { errorMessage } = this.state;

    if (!errorMessage) {
      return null;
    }
    return (
      <div className="text-center Form__outputWrapper u-margin-top-35px grid-x align-center">
        <div className="cell large-8">{errorMessage}</div>
      </div>
    );
  }

  renderPickAreaCode() {
    const { areaCodeInput, errorMessage } = this.state;
    const { numbers, selectedNumber } = this.props;

    if (numbers || selectedNumber) {
      return null;
    }

    return (
      <div className="text-center cell large-10 small-10">
        <h1>{this.getTitle()}</h1>
        <h2 className="h4">
          Burner includes unlimited calls, texts, and pics plus spam blocking.
        </h2>
        <div className="u-margin-top-35px grid-x grid-padding-x align-center-middle">
          <div className="cell large-5 small-12">
            <form onSubmit={this.handleSubmit}>
              <div className="align-bottom Form__inputWrapper u-margin-top-35px grid-x grid-padding-x align-center">
                <label
                  htmlFor="AreaCode"
                  className="text-left Form__fieldWrapper cell auto"
                >
                  <span className="Input__label">Area code</span>
                  <NumberFormat
                    format="###"
                    type="tel"
                    className="Input__field"
                    onValueChange={this.renderAreaCodeChanged}
                    placeholder="XXX"
                  />
                </label>
                <div className="Form__submitWrapper cell shrink">
                  <OauthAwareButton
                    className="button button--notRounded"
                    buttonText="Search"
                    disabled={!areaCodeInput || errorMessage}
                    onClick={this.handleSubmit}
                  />
                </div>
              </div>
              <div className="text-center Form__outputWrapper u-margin-top-35px grid-x align-center">
                <div className="cell large-8">
                  <em>We currently support US and Canadian numbers</em>
                </div>
              </div>
              {this.renderErrorSection()}
            </form>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Header />
        <div className="Main__container Main__container--minHeight grid-x grid-padding-x align-center-middle">
          {this.renderPickAreaCode()}
          {this.renderAvailableNumberList()}
          {this.renderChooseBurnerName()}
        </div>
        <Footer />
      </div>
    );
  }
}

ChooseNumberPage.propTypes = propTypes;

ChooseNumberPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    areaCode: state.chooseNumberPage.areaCode,
    authToken: state.auth.authToken,
    burner: state.chooseNumberPage.burner,
    clientInfo: state.oauthPage.clientInfo,
    numbers: state.chooseNumberPage.numbers,
    isCreatedNumber: state.chooseNumberPage.isCreatedNumber,
    redirectTo: state.auth.redirectTo,
    selectedNumber: state.chooseNumberPage.selectedNumber,
    subscriptions: state.dashboardPage.subscriptions,
    user: state.auth.user,
  };
}

const mapDispatchToProps = { ...actionCreators, ...authActions };

export default connect(mapStateToProps, mapDispatchToProps)(ChooseNumberPage);
