import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import scrollToElement from 'scroll-to-element';

import { STRIPE_KEY } from '../../constants';
import * as actionCreators from '../../ducks/dashboard';
import { renderLocalNumber } from '../../utils/phone';
import {
  isSubscriptionExpired,
  isSubscriptionCancelled,
} from '../../utils/subscription';
import { toHumanDate } from '../../utils/date';
import ChangePaymentForm from '../../components/dashboard/ChangePaymentForm';
import SuccessModal from '../../components/modal/SuccessModal';

const propTypes = {
  authToken: PropTypes.string.isRequired,
  clickCancelSubscription: PropTypes.func.isRequired,
  clickReinstateSubscription: PropTypes.func.isRequired,
  paymentInfo: PropTypes.shape({
    expYear: PropTypes.number,
    country: PropTypes.string,
    brand: PropTypes.string,
    expMonth: PropTypes.number,
    id: PropTypes.string,
    last4: PropTypes.string,
    funding: PropTypes.string,
  }),
  rules: PropTypes.shape({
    inboxRecentMessageAgeLimit: PropTypes.number,
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
      })
    ),
    allowCrossgrade: PropTypes.bool,
    systemNumbers: PropTypes.arrayOf(PropTypes.string),
    subscriptions: PropTypes.arrayOf(
      PropTypes.shape({
        totalConcurrentBurners: PropTypes.number,
        totalBurnersAllowedInPeriod: PropTypes.number,
        sku: PropTypes.string,
      })
    ),
    connections: PropTypes.shape({
      defaultBotHeading: PropTypes.string,
    }),
  }),
  showChangePaymentForm: PropTypes.bool.isRequired,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      price: PropTypes.number,
      store: PropTypes.string,
      burnersAssignedInPeriod: PropTypes.number,
      id: PropTypes.string,
      receipt: PropTypes.string,
      renewalDate: PropTypes.number,
      creationDate: PropTypes.number,
      burnerIds: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  toggleChangePaymentForm: PropTypes.func.isRequired,
  updatePaymentInfo: PropTypes.func.isRequired,
  user: PropTypes.shape({
    trackingId: PropTypes.string,
    sip: PropTypes.shape({
      uri: PropTypes.string,
      password: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    totalNumberBurners: PropTypes.number,
    credits: PropTypes.number,
    version: PropTypes.number,
    id: PropTypes.string,
    dateCreated: PropTypes.number,
    carrierName: PropTypes.string,
    createdSample: PropTypes.bool,
    lastUpdatedDate: PropTypes.number,
    countryCode: PropTypes.string,
    superUser: PropTypes.bool,
    platform: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    lifetimeSubscriptions: PropTypes.number,
  }).isRequired,
};

const defaultProps = {
  paymentInfo: null,
  rules: null,
  subscriptions: null,
};

class AccountSummary extends Component {
  constructor(props) {
    super(props);

    this.renderSubscriptionActions = this.renderSubscriptionActions.bind(this);
    this.handleSubmitChangePaymentForm =
      this.handleSubmitChangePaymentForm.bind(this);
    this.handleToggleChangePaymentForm =
      this.handleToggleChangePaymentForm.bind(this);
    this.renderSubscriptionDetails = this.renderSubscriptionDetails.bind(this);
    this.renderBillingInfo = this.renderBillingInfo.bind(this);
  }

  componentDidMount() {
    Stripe.setPublishableKey(STRIPE_KEY);
  }

  componentDidUpdate(prevProps) {
    const { paymentInfo } = this.props;

    if (prevProps.paymentInfo && prevProps.paymentInfo.id !== paymentInfo.id) {
      scrollToElement(this.refs.billingSection);
    }
  }

  showSubscriptionDetails(sub) {
    const { clickCancelSubscription, clickReinstateSubscription, rules } =
      this.props;
    const totalConcurrentBurners = rules.subscriptions.find(
      (i) => i.sku === sub.sku
    ).totalConcurrentBurners;

    if (isSubscriptionCancelled(sub)) {
      // case where the sub is cancelled but not expired yet
      return (
        <div>
          <p>
            <button
              id="renew-subscription"
              className="flat button selection-button submit"
              onClick={clickReinstateSubscription}
              type="button"
            >
              Renew Subscription
            </button>
          </p>
        </div>
      );
    }

    return (
      <div>
        <p>
          You have {totalConcurrentBurners} Auto-renewing{' '}
          {totalConcurrentBurners > 1 ? 'Lines' : 'Line'} attached to your
          subscription. Use the mobile app for more subscription management
          options.
        </p>
        <p>
          <button
            className="cancel-sub flat button button--notRounded"
            onClick={clickCancelSubscription}
            type="button"
          >
            Cancel Subscription
          </button>
        </p>
      </div>
    );
  }

  handleSubmitChangePaymentForm() {
    const { form, updatePaymentInfo, authToken, user } = this.props;
    const formData = form.changePayment.values;

    updatePaymentInfo(formData, authToken, user.id);
  }

  handleToggleChangePaymentForm(curState) {
    const { toggleChangePaymentForm } = this.props;

    if (!curState) {
      scrollToElement(this.refs.toggleChangePaymentFormLink, {
        duration: 800,
      });
    }

    toggleChangePaymentForm(curState);
  }

  renderSubscriptionActions(subscription) {
    if (isSubscriptionExpired(subscription)) {
      return (
        <p>
          Your Burner subscription expired on{' '}
          {toHumanDate(subscription.renewalDate)}
        </p>
      );
    }

    return (
      <div>
        {this.showSubscriptionDetails(subscription)}
        <p>
          {isSubscriptionCancelled(subscription)
            ? 'Your Burner subscription expires on'
            : 'Auto-renews'}{' '}
          {toHumanDate(subscription.renewalDate)}{' '}
        </p>
      </div>
    );
  }

  renderSubscriptionDetails() {
    const { subscriptions } = this.props;

    if (!subscriptions || !subscriptions[0]) {
      return (
        <Link to="/premium-3">
          <button
            className="flat button button--notRounded submit u-margin-top-35px"
            type="button"
          >
            Subscribe
          </button>
        </Link>
      );
    }

    return (
      <div className="u-margin-top-35px grid-x grid-padding-x align-center-top">
        <div className="cell large-3 small-auto">
          <p className="bold">Subscription</p>
        </div>
        <div className="cell large-9 small-auto">
          {this.renderSubscriptionActions(subscriptions[0])}
        </div>
      </div>
    );
  }

  renderBillingInfo() {
    const { paymentInfo, showChangePaymentForm } = this.props;

    if (paymentInfo) {
      return (
        <div className="u-margin-top-35px grid-x grid-padding-x align-center-top">
          <div className="cell large-3 small-auto">
            <p className="bold" ref="billingSection">
              Billing
            </p>
          </div>
          <div className="cell large-9 small-auto">
            <p>
              {paymentInfo.brand} ending in {paymentInfo.last4}
            </p>
            <p>
              <button
                className="cancel-sub Cta--bold"
                onClick={() =>
                  this.handleToggleChangePaymentForm(showChangePaymentForm)
                }
                ref="toggleChangePaymentFormLink"
                type="button"
              >
                Change Payment Method
              </button>
            </p>
            {showChangePaymentForm && (
              <ChangePaymentForm
                onSubmitChangePaymentForm={this.handleSubmitChangePaymentForm}
                ref="form"
              />
            )}
          </div>
        </div>
      );
    }
  }

  render() {
    const { user } = this.props;

    return (
      <div className="cell small-10 large-7">
        <h1 className="title-heading h2">Account</h1>

        <div className="u-margin-top-35px grid-x grid-padding-x align-center-middle">
          <div className="cell large-3 small-auto">
            <p className="bold">Account phone number</p>
          </div>
          <div className="cell large-9 small-auto">
            <p>{renderLocalNumber(user.phoneNumber)}</p>
          </div>
        </div>

        <div className="grid-x grid-padding-x align-center-middle">
          <div className="cell large-3 small-auto">
            <p className="bold">Credits</p>
          </div>
          <div className="cell large-9 small-auto">
            <p>
              <span className="Dashboard__creditsAmount">{user.credits} </span>
              <span className="Dashboard__creditsNote">
                Visit the Burner app to buy additional credits
              </span>
            </p>
          </div>
        </div>

        {this.renderSubscriptionDetails()}
        {this.renderBillingInfo()}

        <SuccessModal />
      </div>
    );
  }
}

AccountSummary.propTypes = propTypes;
AccountSummary.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    authToken: state.auth.authToken,
    form: state.form,
    paymentInfo: state.dashboardPage.paymentInfo,
    rules: state.dashboardPage.rules,
    showChangePaymentForm: state.dashboardPage.showChangePaymentForm,
    subscriptions: state.dashboardPage.subscriptions,
    user: state.auth.user,
  };
}

const mapDispatchToProps = actionCreators;

export default connect(mapStateToProps, mapDispatchToProps)(AccountSummary);
