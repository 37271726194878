export function extractError(response) {
  if (!response.data) {
    return 'General error occurred, please contact support.';
  }

  if (response.data.error) {
    return response.data.error;
  }

  return response.data;
}
