import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  CHOOSE_NUMBER,
  CUSTOM_WEB_EVENT,
  NUMBERS_RECEIVED,
  RESELECT_AREA_CODE,
} from '../../constants';
import * as actionCreators from '../../ducks/subscribe';
import AvailableNumberList from '../../components/AvailableNumberList';

const propTypes = {
  areaCode: PropTypes.string.isRequired,
  maxResultsCount: PropTypes.number,
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      phoneNumber: PropTypes.string,
      state: PropTypes.string,
      rateCenter: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  onChosenNumber: PropTypes.func.isRequired,
  requestNumber: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
};

const defaultProps = {
  maxResultsCount: null,
  numbers: null,
};

class NumberPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaCode: this.props.areaCode,
    };

    this.chooseAgain = this.chooseAgain.bind(this);
    this.searchAgain = this.searchAgain.bind(this);
    this.selectNumber = this.selectNumber.bind(this);
  }

  componentDidMount() {
    $(':input[type=number]').on('mousewheel', () => {
      $(this).blur();
    });
  }

  chooseAgain() {
    const { resetSearch } = this.props;
    const { router } = this.context;

    dataLayer.push({
      event: CUSTOM_WEB_EVENT,
      customWebEventName: RESELECT_AREA_CODE,
    });
    resetSearch();
    router.push('/areaCode');
  }

  searchAgain() {
    const { resetSearch } = this.props;
    const { router } = this.context;

    dataLayer.push({
      event: CUSTOM_WEB_EVENT,
      customWebEventName: NUMBERS_RECEIVED,
      customWebEventAction: false,
    });
    resetSearch();
    router.push('/areaCode');
  }

  selectNumber(number) {
    const { areaCode } = this.state;
    const { onChosenNumber, numbers } = this.props;
    const { router } = this.context;

    dataLayer.push(
      {
        event: CUSTOM_WEB_EVENT,
        customWebEventName: CHOOSE_NUMBER,
        customWebEventAction: areaCode,
      },
      {
        event: CUSTOM_WEB_EVENT,
        customWebEventName: NUMBERS_RECEIVED,
        customWebEventAction: !!numbers.find((num) =>
          num.phoneNumber.startsWith(`+1${areaCode}`)
        ),
      }
    );
    onChosenNumber(number);
    router.push('/subscription-options');
  }

  render() {
    const { areaCode } = this.state;
    const { numbers, maxResultsCount } = this.props;

    if (!numbers) {
      return null;
    }

    if (numbers.length === 0) {
      return (
        <div>
          <h5 className="center">
            Sorry, there are no numbers available with that area code. Please{' '}
            <button
              className="Cta Cta--bold burner-link"
              type="button"
              onClick={this.searchAgain}
            >
              try a different one.
            </button>{' '}
          </h5>
        </div>
      );
    }

    return (
      <section>
        <AvailableNumberList
          chosenAreaCode={areaCode}
          numbers={numbers}
          maxResultsCount={maxResultsCount}
          onChosenNumber={this.selectNumber}
        />
        <div className="PickNumber__askNewNumber u-margin-top-35px">
          Don’t see a number you like?{' '}
          <button
            className="Cta Cta--bold burner-link"
            onClick={this.chooseAgain}
            type="button"
          >
            Try another area code.
          </button>
        </div>
      </section>
    );
  }
}

NumberPicker.propTypes = propTypes;
NumberPicker.defaultProps = defaultProps;
NumberPicker.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  maxResultsCount: state.chooseNumberPage.maxResultsCount,
  numbers: state.subscribePage.numbers,
});

const mapDispatchToProps = actionCreators;

export default connect(mapStateToProps, mapDispatchToProps)(NumberPicker);
