import { requestDevConnect, requestDevUnlink, closeConfirmModal } from './general';

export const SHOW_CONFIRM_DEV_CONNECTION = 'burner-web/confirm/SHOW_CONFIRM_DEV_CONNECTION';
export const CANCEL_CONFIRM_MODAL = 'burner-web/confirm/CANCEL_CONFIRM_MODAL';

// reducer that handles direct logic for handling confirmation dialogs
export default function reducer(state = {}, action) {
  switch (action.type) {
    case SHOW_CONFIRM_DEV_CONNECTION:
      return Object.assign({}, state, {
        burnerId: action.burnerId,
        showPrompt: true,
        title: action.title,
        text: action.text,
        toggleOn: action.toggleOn,
      });
    case CANCEL_CONFIRM_MODAL:
      return Object.assign({}, state, {
        showPrompt: false,
      });
    default:
      return state;
  }
}

export function cancelModal() {
  return closeConfirmModal();
}

export function confirmConnect(userId, authToken, burnerId) {
  return (dispatch) => {
    dispatch(requestDevConnect(userId, authToken, burnerId));
  };
}

export function confirmUnlink(userId, authToken, burnerId) {
  return (dispatch) => {
    dispatch(requestDevUnlink(userId, authToken, burnerId));
  };
}
