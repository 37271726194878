import { getBurnerConnections, receiveBurnersList, RECEIVE_BURNERS_LIST } from './general';
import { confirmDevConnection } from './burnerDetails';

import {
  DEV_CONNECT_SUCCESS,
  NO_CONNECTION_ACTION,
  RECEIVE_BURNER_DETAILS,
  RECEIVE_CONNECTION_SETTINGS,
} from '../../src/ducks/burnerDetails';

export default function reducer(state = { burners: [], burnerToConnectionMap: {} }, action) {
  switch (action.type) {
    case RECEIVE_BURNERS_LIST:
      return Object.assign({}, state, {
        burners: action.burners,
      });
    case NO_CONNECTION_ACTION:
      return Object.assign({}, state, {
        burnerToConnectionMap: Object.assign({}, state.burnerToConnectionMap, { [action.burnerId]: false }),
      });
    case RECEIVE_CONNECTION_SETTINGS:
      return Object.assign({}, state, {
        burnerToConnectionMap: Object.assign({}, state.burnerToConnectionMap, { [action.burnerId]: true }),
      });
    case DEV_CONNECT_SUCCESS:
      return Object.assign({}, state, {
        burnerConnected: true,
        connectedBurnerId: action.burnerId,
      });
    case RECEIVE_BURNER_DETAILS: // user has navigated to the settings page, in this case reset the state of the dev page (in case they come back to it).
      return Object.assign({}, state, {
        burnerConnected: false,
        connectedBurnerId: null,
      });
    default:
      return state;
  }
}

export function getConnectionsForBurners(burners, params) {
  return (dispatch) => {
    dispatch(receiveBurnersList(burners));
    if (burners) {
      for (const burner of burners.values()) {
        dispatch(getBurnerConnections(params.authToken, params.userId, burner.id));
      }
    }
  };
}

export function confirm(burnerId) {
  return confirmDevConnection(true, burnerId);
}
