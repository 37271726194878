import axios from 'axios';
import { removeNulls } from '../utils/general';
import { requesting } from '../ducks/general';

export const CREATE_ACCOUNT = 'burner-web/sitebuilder/dashboard/CREATE_ACCOUNT';
export const SET_JWT = 'burner-web/sitebuilder/dashboard/SET_JWT';

const initialState = {
  subscriptionCancelled: false,
  showChangePaymentForm: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ACCOUNT:
      return state;
    case SET_JWT:
      return Object.assign({}, { instanceJwt: action.payload });
    default:
      return state;
  }
}

export function postSetupStatus(jwt) {
  return (dispatch) => {
    dispatch(requesting());
    return axios
      .post(
        'https://appmarket-publicapi.sitebuilder.com/v1.0/dashboard/setupstatus',
        removeNulls({
          Setup_Status: 100,
        }),
        {
          headers: {
            'instance-jwt': jwt,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(() => dispatch({ type: CREATE_ACCOUNT }));
  };
}

export function setInstanceJwt(jwt) {
  return {
    type: SET_JWT,
    payload: jwt,
  };
}
