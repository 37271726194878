import React, { Component } from 'react';

class LoggedInHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavCollapsed: true,
    };

    this.handleNavCollapse = this.handleNavCollapse.bind(this)
  }

  handleNavCollapse() {
    this.setState(() => ({
      isNavCollapsed: !this.state.isNavCollapsed,
    }));
  }

  renderBurnerImage(logo) {
    return (
      <a href="https://www.burnerapp.com/">
        <img src={logo} alt="Burner" title="Burner" />
      </a>
    );
  }

  renderSmallFormatHeader() {
    const GoogleBadge = require('images/play-store-badge.png');
    const AppleBadge = require('images/app-store-badge.png');
    const { isNavCollapsed } = this.state;
    console.log(this.state);

    return (
      <div
        id="logged-in-header-sm"
        className="align-right-middle cell small-8"
      >
        <div className="NavMenu align-right">
          <div className="menu-container">
            <div
              className="title-bar align-right text-right"
            >
              <button
                className="menu-icon"
                type="button"
                onClick={this.handleNavCollapse}
              />
              <div className="title-bar-title"></div>
            </div>

            <div
              className={`${isNavCollapsed ? 'top-bar collapsed' : 'top-bar opened-navbar'}`}
              id="responsive-menu-small"
            >
              <ul
                className="vertical medium-horizontal dropdown menu"
                data-dropdown-menu
              >
                <li>
                  <a href="https://www.burnerapp.com/how-burner-works">
                    How Burner works
                  </a>
                </li>
                <li>
                  <a href="/dashboard">
                    My Account
                  </a>
                </li>
                <li>
                  <a href="https://www.burnerapp.com/pricing">
                    Plans & pricing
                  </a>
                </li>
                <li>
                  <a href="https://www.burnerapp.com/blog">Blog</a>
                </li>
                <li>
                  <a
                    href="https://app.adjust.com/oc0osf6_8fmse8n?fallback=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&utm_source=burnerapp&utm_medium=cta_header&utm_campaign=web-app&redirect_macos=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&utm_source=burnerapp&utm_medium=cta_header&utm_campaign=web-app"
                    className="button button--notRounded"
                  >
                    Get Burner
                  </a>
                </li>
                <li>
                  <div className="Header__downloadBadges">
                    <a href="https://app.adjust.com/gegg2hg?fallback=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&amp;utm_source=burnerapp&amp;utm_medium=app_store&amp;utm_campaign=confirmation-page&amp;redirect_macos=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&amp;utm_source=burnerapp&amp;utm_medium=app_store&amp;utm_campaign=confirmation-page">
                      <img
                        src={AppleBadge}
                        loading="lazy"
                        alt="Download on the App Store"
                      />
                    </a>
                    <a href="https://app.adjust.com/9j9egjp?fallback=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&amp;utm_source=burnerapp&amp;utm_medium=play_store&amp;utm_campaign=confirmation-page&amp;redirect_macos=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&amp;utm_source=burnerapp&amp;utm_medium=play_store&amp;utm_campaign=confirmation-page">
                      <img
                        src={GoogleBadge}
                        loading="lazy"
                        alt="Get it on Google Play"
                      />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderLargeFormatHeader() {
    return (
      <div
        id="logged-in-header-med"
        className="align-right-middle medium-9"
      >
        <div className="NavMenu align-right">
          <div className="menu-container">
            <div className="top-bar" id="responsive-menu">
              <ul
                className="vertical medium-horizontal dropdown menu"
              >
                <li>
                  <a href="https://www.burnerapp.com/how-burner-works">
                    How Burner works
                  </a>
                </li>
                <li>
                  <a href="/dashboard">
                    My Account
                  </a>
                </li>
                <li>
                  <a href="https://www.burnerapp.com/pricing">
                    Plans & pricing
                  </a>
                </li>
                <li>
                  <a href="https://www.burnerapp.com/blog">Blog</a>
                </li>
                <li>
                  <a
                    href="https://app.adjust.com/oc0osf6_8fmse8n?fallback=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&utm_source=burnerapp&utm_medium=cta_header&utm_campaign=web-app&redirect_macos=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&utm_source=burnerapp&utm_medium=cta_header&utm_campaign=web-app"
                    className="button button--notRounded"
                  >
                    Get Burner
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const logo = require('images/logo-wordmark.png');

    return (
      <header className="Header align-center grid-x">
        <div className="Header__logo cell small-4 medium-3">
          {this.renderBurnerImage(logo)}
        </div>
        {this.renderSmallFormatHeader()}
        {this.renderLargeFormatHeader()}
      </header>
    );
  }
}


export default LoggedInHeader;
