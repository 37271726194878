import axios from 'axios';
import { BASE_URL } from '../constants';
import { CANCEL_CONFIRM_MODAL } from './confirm';
import { hasError } from './error';
import { requesting, closeConfirmModal } from './general';
import { extractError } from '../utils/request';

export const RECEIVE_AUTHORIZATIONS = 'burner-web/applications/RECEIVE_AUTHORIZATIONS';
export const REVOKE_AUTHORIZATION_SUCCESS = 'burner-web/applications/REVOKE_AUTHORIZATION_SUCCESS';
export const SHOW_CONFIRM_REVOKE_MODAL = 'burner-web/applications/SHOW_CONFIRM_REVOKE_MODAL';

/**
 * State for the list authorized applications page
 */
export default function reducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_AUTHORIZATIONS: // reset here so we show the first flow
      return Object.assign({}, state, {
        authorizations: action.authorizations,
      });
    case REVOKE_AUTHORIZATION_SUCCESS:
      return Object.assign({}, state, {
        showPrompt: false,
        authorizations: state.authorizations.filter((authorization) => authorization.clientId !== action.clientId),
      });
    case SHOW_CONFIRM_REVOKE_MODAL:
      return Object.assign({}, state, {
        showPrompt: true,
        selectedAuthorization: action.selectedAuthorization,
      });
    case CANCEL_CONFIRM_MODAL:
      return Object.assign({}, state, {
        showPrompt: false,
      });
    default:
      return state;
  }
}

export function receiveAuthorizations(authorizations) {
  return {
    type: RECEIVE_AUTHORIZATIONS,
    authorizations,
  };
}

export function receiveRevokeAuthorization(clientId) {
  return {
    type: REVOKE_AUTHORIZATION_SUCCESS,
    clientId,
  };
}

export function showConfirmRevokeModal(selectedAuthorization) {
  return {
    type: SHOW_CONFIRM_REVOKE_MODAL,
    selectedAuthorization,
  };
}

export function init(userId, authToken) {
  return (dispatch) => {
    dispatch(requesting());

    return axios
      .get(`${BASE_URL}/user/${userId}/oauth/authorizations`, {
        headers: {
          Authentication: authToken,
        },
      })
      .then((resp) => {
        dispatch(receiveAuthorizations(resp.data));
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status));
      });
  };
}

/**
 * Will call our endpoint to revoke an authorization
 */
export function revokeAuthorization(userId, authToken, authorization) {
  return (dispatch) => {
    dispatch(requesting());

    return axios
      .delete(`${BASE_URL}/user/${userId}/oauth/authorizations?access_token=${authorization.accessToken}`, {
        headers: {
          Authentication: authToken,
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        dispatch(receiveRevokeAuthorization(authorization.clientId));
        dispatch(closeConfirmModal());
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status));
      });
  };
}
