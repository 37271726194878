import React from 'react';
import PropTypes from 'prop-types';

import { SUPPORT_ARTICLE_CANCEL_SUBSCRIPTION } from '../constants';
import Header from '../components/common/Header';
import Footer from '../components/oauth/Footer';
import SubscriptionButton from '../components/purchase/SubscriptionButton';
import '!style-loader!css-loader!sass-loader!../../styles/subscription-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/number-picker-rebrand.scss';

const propTypes = {
  discountPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  featureIconAutoRenew: PropTypes.string.isRequired,
  featureIconSpam: PropTypes.string.isRequired,
  featureIconSwap: PropTypes.string.isRequired,
  featureIconUnlimited: PropTypes.string.isRequired,
  selectPlan: PropTypes.func.isRequired,
  toMonthlyPrice: PropTypes.func.isRequired,
  oneLineMonthly: PropTypes.shape({
    sku: PropTypes.string,
    price: PropTypes.string,
    numLines: PropTypes.number,
    isYearly: PropTypes.bool,
  }).isRequired,
  oneLineYearly: PropTypes.shape({
    sku: PropTypes.string,
    price: PropTypes.string,
    numLines: PropTypes.number,
    isYearly: PropTypes.bool,
  }).isRequired,
};

function ChooseSubscriptionPage({
  discountPercent,
  selectPlan,
  toMonthlyPrice,
  oneLineMonthly,
  oneLineYearly,
}) {
  return (
    <div>
      <Header />
      <div className="Main__container Main__container--minHeight grid-x grid-padding-x align-center-middle">
        <div className="cell large-6 small-11">
          <div className="text-center number-picker">
            <div>
              <h1>Choose the right plan for you</h1>
            </div>
            <div
              id="choose-subscription"
              className="SubscriptionPlans__container grid-x grid-padding-x align-center-middle"
            >
              <SubscriptionButton
                selectPlan={selectPlan}
                subscriptionPlan={oneLineMonthly}
              />
              <SubscriptionButton
                selectPlan={selectPlan}
                subscriptionPlan={oneLineYearly}
                toMonthlyPrice={toMonthlyPrice}
                discountPercent={discountPercent}
              />
            </div>
            <div className="Subscription__disclaimer">
              Your subscription automatically renews.
              <div className="u-inline-links">
                <a
                  href="https://www.adhoclabs.co/terms-of-service"
                  target={'_blank'}
                >
                  Terms & Conditions
                </a>
                <a href={SUPPORT_ARTICLE_CANCEL_SUBSCRIPTION} target={'_blank'}>
                  Cancel anytime
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

ChooseSubscriptionPage.propTypes = propTypes;

export default ChooseSubscriptionPage;
