import React from 'react';
import { Link } from 'react-router';

function Footer() {
  const burnerLogo = require('images/logo-wordmark.png');
  const socialIconInstagram = require('images/icons/social/icon-instagram-white.png');
  const socialIconFacebook = require('images/icons/social/icon-facebook-white.png');
  const socialIconTwitter = require('images/icons/social/icon-twitter-white.png');
  const socialIconLinkedIn = require('images/icons/social/icon-linkedin-white.png');

  return (
    <footer className="grid-x grid-padding-x align-center-middle">
      <div className="cell large-7">
        <div className="grid-x grid-margin-x">
          <div className="Footer__logo cell large-auto small-12">
            <Link to="/">
              <img src={burnerLogo} alt="Burner logo" />
            </Link>
          </div>
          <div className="Footer__socialNetworks cell large-auto small-12">
            <div className="align-middle SocialNetworks grid-x align-right">
              <div className="SocialNetworks__heading">Stay in touch</div>
              <div className="SocialNetworks__iconsWrapper">
                <a
                  href="https://instagram.com/burnerapp"
                  target="_blank"
                  className="SocialNetworks__icon"
                >
                  <img src={socialIconInstagram} alt="Follow us on Instagram" />
                </a>
                <a
                  href="https://twitter.com/burner"
                  target="_blank"
                  className="SocialNetworks__icon"
                >
                  <img src={socialIconTwitter} alt="Follow us on Twitter" />
                </a>
                <a
                  href="https://facebook.com/burnerapp"
                  target="_blank"
                  className="SocialNetworks__icon"
                >
                  <img src={socialIconFacebook} alt="Follow us on Facebook" />
                </a>
                <a
                  href="https://www.linkedin.com/company/ad-hoc-labs/"
                  target="_blank"
                  className="SocialNetworks__icon"
                >
                  <img src={socialIconLinkedIn} alt="Follow us on LinkedIn" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="FooterNav">
          <div className="grid-x grid-margin-x">
            <div className="FooterNav__column cell large-auto">
              <h4 className="FooterNav__heading">Get started</h4>
              <ul className="FooterNav__list">
                <li>
                  <a
                    className="FooterNav__link"
                    target="_blank"
                    href="https://app.adjust.com/e5hq2v"
                  >
                    Get Burner for iPhone
                  </a>
                </li>
                <li>
                  <a
                    className="FooterNav__link"
                    target="_blank"
                    href="https://app.adjust.com/gn09vb"
                  >
                    Get Burner for Android
                  </a>
                </li>
                <li>
                  <a
                    className="FooterNav__link"
                    href="https://www.burnerapp.com/pricing"
                  >
                    Plans & pricing
                  </a>
                </li>
              </ul>
            </div>
            <div className="FooterNav__column cell large-auto">
              <h4 className="FooterNav__heading">Top Features</h4>
              <ul className="FooterNav__list">
                <li>
                  <a
                    className="FooterNav__link"
                    href="https://www.burnerapp.com/area-code-index"
                  >
                    Popular area codes
                  </a>
                </li>
                <li>
                  <a
                    className="FooterNav__link"
                    href="https://dialed.com/"
                  >
                    Need a number for business? Try Dialed.
                  </a>
                </li>
                <li>
                  <a
                    className="FooterNav__link"
                    href="https://www.burnerapp.com/blog/fake-phone-number-online-dating"
                  >
                    Burner for dating
                  </a>
                </li>
              </ul>
            </div>
            <div className="FooterNav__column cell large-auto">
              <h4 className="FooterNav__heading">About</h4>
              <ul className="FooterNav__list">
                <li>
                  <a
                    className="FooterNav__link"
                    target="_blank"
                    href="https://www.adhoclabs.co/about"
                  >
                    Meet the team
                  </a>
                </li>
                <li>
                  <a
                    className="FooterNav__link"
                    target="_blank"
                    href="https://www.adhoclabs.co/careers"
                  >
                    Careers
                  </a>
                </li>
                <li>
                  <a
                    className="FooterNav__link"
                    href="https://www.burnerapp.com/press"
                  >
                    Press
                  </a>
                </li>
                <li>
                  <a
                    className="FooterNav__link"
                    href="https://support.burnerapp.com/"
                  >
                    Support
                  </a>
                </li>
              </ul>
            </div>
            <div className="FooterNav__column cell large-auto">
              <h4 className="FooterNav__heading">Privacy</h4>
              <ul className="FooterNav__list">
                <li>
                  <a
                    className="FooterNav__link"
                    href="https://www.burnerapp.com/what-is-a-burner-phone"
                  >
                    What is Burner?
                  </a>
                </li>
                <li>
                  <a
                    className="FooterNav__link"
                    href="https://www.burnerapp.com/blog/what-is-data-privacy"
                  >
                    Data privacy
                  </a>
                </li>
                <li>
                  <a
                    className="FooterNav__link"
                    href="https://www.burnerapp.com/blog/can-a-burner-phone-be-traced"
                  >
                    Can Burner be traced?
                  </a>
                </li>
                <li>
                  <a
                    className="FooterNav__link"
                    href="https://www.burnerapp.com/blog/how-to-hide-phone-number"
                  >
                    Hide your number
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="Footer__credits">
          <div className="grid-x grid-margin-x">
            <div className="cell large-shrink">
              ©2021 Ad Hoc Labs. All rights reserved.
            </div>
            <div className="Footer__disclaimer cell large-auto u-inline-links">
              <a
                target="_blank"
                href="https://www.adhoclabs.co/privacy-policy"
                target={'_blank'}
              >
                Privacy
              </a>{' '}
              <a
                target="_blank"
                href="https://www.adhoclabs.co/terms-of-service"
                target={'_blank'}
              >
                Terms of Service
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
