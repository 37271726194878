import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actionCreators from '../ducks/verify';
import * as authActions from '../ducks/auth';
import { requestNumber } from '../ducks/subscribe';
import * as oauthActions from '../ducks/oauth';
import * as chooseNumberActions from '../ducks/chooseNumber';
import { resetVerifyCodeError } from '../ducks/error';
import { OAUTH_REDIRECT_PARAMS } from '../constants';
import Footer from '../components/oauth/Footer';
import queryString from 'query-string';
import OauthAwareHeader from '../components/oauth/OauthAwareHeader';
import OauthAwareButton from '../components/oauth/OauthAwareButton';
import { renderNumber } from '../utils/phone';

import { NUX_NUMBER_VERIFICATION_WEB, CUSTOM_WEB_EVENT, NUX_NUMBER_SELECTION_WEB, SIGNUP } from '../constants.js';

import OauthAwareAnchor from '../components/oauth/OauthAwareAnchor';

class CPlusVerifyPage extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.handleResendClick = this.handleResendClick.bind(this);
    this.updateAcceptTos = this.updateAcceptTos.bind(this);
    this.handleVerifyCodeChange = this.handleVerifyCodeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      acceptTos: null,
      showAuthorizeText: false,
    };
  }

  componentDidMount() {
    const oauthParams = localStorage.getItem(OAUTH_REDIRECT_PARAMS);
    const redirectQuery = `${oauthParams}`;
    this.queryValues = queryString.parse(redirectQuery);
    this.props.getHumanFriendlyScopes(this.queryValues.scope, null);
    const { phone: phoneNumber } = this.props.params;
    const areaCode = phoneNumber.substring(2, 5);
    this.props.requestSingleNumber(areaCode);
    this.props.fetchVerifyCode(phoneNumber, false, null, () => {
      this.context.router.goBack();
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.userVerified && nextProps.userVerified) {
      if (!nextProps.user.totalNumberBurners) {
        dataLayer.push({
          event: CUSTOM_WEB_EVENT,
          customWebEventName: NUX_NUMBER_VERIFICATION_WEB,
        });
        this.createBurner(nextProps);
      } else {
        const oauthParams = localStorage.getItem(OAUTH_REDIRECT_PARAMS);
        this.context.router.push(nextProps.redirectTo + oauthParams);
        return;
      }
    }

    if (nextProps.error.errorMessage && this.state.verifyCode) {
      this.setState({ verifyCode: '' });
    }

    if (nextProps.isCreatedNumber) {
      const { reset, sampleCreated } = this.props;
      reset(); // resets the state of this page so if user wants to create another burner, it starts from the beginning
      sampleCreated();
      dataLayer.push({ event: CUSTOM_WEB_EVENT, customWebEventName: SIGNUP });
      this.authorizeUser(nextProps);
    }
    if (nextProps.authorizeCode) {
      this.context.router.push('/cplussuccess');
    }
  }

  createBurner(nuProps) {
    const { authToken, user, createBurner, selectedNumber, areaCode, clientInfo } = nuProps;

    dataLayer.push({
      event: CUSTOM_WEB_EVENT,
      customWebEventName: NUX_NUMBER_SELECTION_WEB,
      customWebEventAction: areaCode,
    });

    createBurner(authToken, user.id, null, selectedNumber.phoneNumber, null, clientInfo ? clientInfo.sampleSku : null);
  }

  authorizeUser(nuProps) {
    const { client_id, state, scope, redirect_uri } = this.queryValues;
    const selectedBurnerIds = [nuProps.burner.id];
    const { authToken, user } = nuProps;
    nuProps.authorize(authToken, user.id, client_id, state, scope, redirect_uri, selectedBurnerIds, () => {
      this.context.router.push(`/?client_id=${client_id}`);
    });
  }

  handleSubmit(e) {
    const { validateVerifyCode } = this.props;
    e.preventDefault();

    if (!this.state.verifyCode) {
      this.setState({ errorMessage: "That verification code isn't valid." });
      return;
    }

    if (this.state.verifyCode.length !== 6) {
      this.setState({
        errorMessage: 'Verification code should be six digits.',
      });
      return;
    }

    if (!this.state.acceptTos) {
      this.setState({ acceptTos: false }); // we set this explicitly to false to denote an error on the UI
      return;
    }

    validateVerifyCode(this.props.params.phone, this.state.verifyCode);
  }

  handleResendClick(e) {
    this.props.fetchVerifyCode(this.props.params.phone, true);
    e.preventDefault();
  }

  maybeRenderResentMessage() {
    const { isResend } = this.props;
    if (!isResend) {
      return null;
    }

    return (
      <div className="medium-12">
        <div data-alert="" className="info box">
          <span className="title">Verification code resent!</span>
        </div>
      </div>
    );
  }

  updateAcceptTos(e) {
    this.setState({ acceptTos: e.target.checked });
  }

  handleVerifyCodeChange(event) {
    this.setState({ errorMessage: null, verifyCode: event.target.value });
    if (event.target.value === '') {
      this.props.resetVerifyCodeError();
    }
  }

  renderVerifyCodeErrorSection() {
    if (!this.state.errorMessage && !this.props.verifyErrorMessage) {
      return null;
    }

    return <div className="error error-msg">{this.state.errorMessage || this.props.verifyErrorMessage}</div>;
  }

  renderTosErrorSection() {
    if (this.state.acceptTos === true || this.state.acceptTos === null) {
      return null;
    }

    return (
      <div className="error bold please-agree">
        <i className="fa fa-exclamation-triangle error-msg" /> Please agree to our policies
      </div>
    );
  }

  renderHumanFriendlyScopes() {
    const { humanFriendlyScopes } = this.props;
    const html = humanFriendlyScopes.map((scope, index) => (
      <div key={index}>
        <p style={{ lineHeight: '1.5em', marginTop: '1.5em' }}>
          <span style={{ fontSize: '1.25em' }} className="scope-message" dangerouslySetInnerHTML={scope} />
        </p>
      </div>
    ));
    return (
      <div style={{ border: 'solid 1px #e9e9e9', marginTop: '1em' }}>
        <div
          style={{
            marginBottom: '0',
            marginTop: '1.5em',
            fontSize: '1.25em',
            padding: '0 .5em',
          }}
        >
          <p>Cplus will be granted the following permissions</p>
        </div>
        <div style={{ textAlign: 'left', padding: '0.25em 1.5em' }} className="scope-messages">
          {html}
        </div>
      </div>
    );
  }

  renderAuthorize() {
    const { showAuthorizeText } = this.state;
    const buttonText = showAuthorizeText ? 'Close Permissions' : 'See Permissions';
    return (
      <div>
        <div>Once you authorize, cplus will be granted a few basic permissions</div>
        <button
          style={{
            marginTop: '1em',
            textDecoration: 'underline',
            color: '#5b5b96',
          }}
          onClick={(e) => {
            e.preventDefault();
            this.setState({ showAuthorizeText: !showAuthorizeText });
          }}
        >
          {buttonText}
        </button>
        {showAuthorizeText && this.renderHumanFriendlyScopes()}
      </div>
    );
  }

  renderNewNumber() {
    if (!this.props.numberUnavailable) return null;
    const { clientInfo, selectedNumber } = this.props;
    return (
      <form onSubmit={() => this.createBurner(this.props)}>
        <div className="row">
          <div className="row small-12 small-offset-0 large-6 large-offset-3 columns">
            <h5 className="desc">
              Sorry! Your number just got grabbed by another user. Here's your new number...for real this time.
            </h5>
          </div>
          <div style={{ marginTop: '2em', fontSize: '1.25em' }}>{renderNumber(selectedNumber.phoneNumber)}</div>
          <div className="accept-container float-none">
            <OauthAwareButton clientInfo={clientInfo} className="flat button expanded submit" buttonText="Next" />
            <Footer clientInfo={clientInfo} />
          </div>
        </div>
      </form>
    );
  }

  renderNumber() {
    const { selectedNumber, clientInfo } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row input-container">
          <div className="small-12 small-offset-0 large-6 large-offset-3 columns">
            {selectedNumber && (
              <div>
                <h5 style={{ marginBottom: '1.5em' }}>Viola here's your new Burner number</h5>
                <h6 style={{ marginBottom: '1.5em' }}>{renderNumber(selectedNumber.phoneNumber)}</h6>
              </div>
            )}
            <h5 className="desc">Enter your verification code</h5>
            <input
              className={`main-input  ${this.state.errorMessage || this.props.verifyErrorMessage ? 'error-input' : ''}`}
              type="tel"
              maxLength="6"
              placeholder="######"
              onChange={this.handleVerifyCodeChange}
            />
            {this.renderVerifyCodeErrorSection()}
          </div>

          <div className="small-12 small-offset-0 large-6 large-offset-3 columns resend-code-container">
            <OauthAwareAnchor
              clientInfo={clientInfo}
              onClick={this.handleResendClick}
              className="resend-code"
              anchorText="Resend code"
            />
            {this.maybeRenderResentMessage()}
          </div>

          <div className="accept-container float-none">
            <input id="acceptTos" type="checkbox" onChange={this.updateAcceptTos} />
            <label htmlFor="acceptTos">
              I agree to Burner's&nbsp;
              <OauthAwareAnchor
                clientInfo={clientInfo}
                href="http://www.burnerapp.com/terms-of-service"
                target="_blank"
                className="resend-code"
                anchorText="Terms of Service"
              />
              &nbsp;&&nbsp;
              <OauthAwareAnchor
                clientInfo={clientInfo}
                href="http://www.burnerapp.com/privacy"
                target="_blank"
                className="resend-code"
                anchorText="Privacy Policy"
              />
            </label>
            {this.renderTosErrorSection()}
            <OauthAwareButton clientInfo={clientInfo} className="flat button expanded submit" buttonText="Authorize" />
            {this.renderAuthorize()}
            <Footer clientInfo={clientInfo} />
          </div>
        </div>
      </form>
    );
  }

  renderPinInput() {
    const { codeWasSent, clientInfo, numberUnavailable } = this.props;
    if (codeWasSent === false) {
      return null;
    }
    return (
      <div className="root-container" id="webflow-container">
        <OauthAwareHeader clientInfo={clientInfo} />

        <div className="main-container">{numberUnavailable ? this.renderNewNumber() : this.renderNumber()}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="row main-app">
        <div className="large-12 columns">{this.renderPinInput()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    codeWasSent: state.verifyPage.codeWasSent,
    userVerified: state.verifyPage.userVerified,
    isFetching: state.verifyPage.isFetching,
    isResend: state.verifyPage.isResend,
    user: state.auth.user,
    redirectTo: state.auth.redirectTo,
    error: state.error,
    clientInfo: state.oauthPage.clientInfo,
    verifyErrorMessage: state.verifyPage.verifyErrorMessage,
    numbers: state.chooseNumberPage.numbers,
    isCreatedNumber: state.chooseNumberPage.isCreatedNumber,
    selectedNumber: state.chooseNumberPage.selectedNumber,
    numberUnavailable: state.chooseNumberPage.numberUnavailable,
    authToken: state.auth.authToken,
    burner: state.chooseNumberPage.burner,
    authorizeCode: state.oauthPage.authorizeCode,
    humanFriendlyScopes: state.oauthPage.humanFriendlyScopes,
  };
}

export default connect(
  mapStateToProps,
  {
    ...authActions,
    ...actionCreators,
    ...chooseNumberActions,
    ...oauthActions,
    requestNumber,
    resetVerifyCodeError,
  }
)(CPlusVerifyPage);
