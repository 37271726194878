import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actionCreators from '../ducks/subscribe';
import { logPurchase } from '../utils/analytics';
import { getCurrentUser } from '../utils/user';
import ConfirmationPage from '../pages/ConfirmationPage';

const propTypes = {
  discountAmount: PropTypes.number,
  freeTrialLength: PropTypes.number,
  numberAssociated: PropTypes.bool,
  selectedNumber: PropTypes.string.isRequired,
  subscription: PropTypes.shape({
    totalConcurrentBurners: PropTypes.number,
    totalBurnersAllowedInPeriod: PropTypes.number,
    sku: PropTypes.string,
  }),
  accountPhoneNumber: PropTypes.string.isRequired,
  userEmail: PropTypes.string,
};

const defaultProps = {
  discountAmount: 0,
  freeTrialLength: null,
  numberAssociated: false,
  subscription: null,
  userEmail: null,
};

class ConfirmationPageContainer extends Component {
  componentDidMount() {
    const { subscription, freeTrialLength } = this.props;
    const price = freeTrialLength ? 0 : subscription.price / 100;

    logPurchase(
      subscription.id,
      subscription.sku,
      price,
      subscription.name,
      getCurrentUser(),
    );
  }

  render() {
    const {
      subscription,
      discountAmount,
      userEmail,
      selectedNumber,
      freeTrialLength,
      accountPhoneNumber,
    } = this.props;

    return (
      <ConfirmationPage
        discountAmount={discountAmount}
        freeTrialLength={freeTrialLength}
        selectedNumber={selectedNumber}
        subscription={subscription}
        userEmail={userEmail}
        userPhoneNumber={accountPhoneNumber}
      />
    );
  }
}

ConfirmationPageContainer.propTypes = propTypes;
ConfirmationPageContainer.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  freeTrialLength: state.subscribePage.freeTrialLength,
  numberAssociated: state.subscribePage.numberAssociated,
  selectedNumber: state.subscribePage.selectedNumber,
  subscription: state.subscribePage.subscription,
  accountPhoneNumber: state.subscribePage.accountPhoneNumber,
  userEmail: state.subscribePage.userEmail,
});

const mapDispatchToProps = actionCreators;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationPageContainer);
