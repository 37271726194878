import React from 'react';
function Features() {
  return (
    <div>
      <ul id="subscription-feature-list" className="Features">
        <li>Unlimited calls & texts</li>
        <li>Spam blocking</li>
        <li>Picture messaging</li>
        <li>Number swaps</li>
        <li>Auto-reply texts</li>
        <li>Voicemail</li>
        <li>Muting & blocking</li>
      </ul>
    </div>
  );
}

export default Features;
