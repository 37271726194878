import React from 'react';

function AppButtons() {
  const appStoreBadge = require('images/app-store-badge.png');
  const playStoreBadge = require('images/play-store-badge.png');

  return (
    <div className="AppButtons grid-x align-center">
      <div className="cell shrink">
        <a href="https://app.adjust.io/anc82e">
          <img
            src={appStoreBadge}
            alt="App Store Download"
            id="btn-ios"
            className="AppButton__img"
          />
        </a>
        <a href="https://app.adjust.io/53balo">
          <img
            src={playStoreBadge}
            alt="Play Store Download"
            id="btn-android"
            className="AppButton__img"
          />
        </a>
      </div>
    </div>
  );
}

export default AppButtons;
