import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import DevTools from './devTools';

const enhancer = __DEVELOPMENT__
  ? compose(
      applyMiddleware(thunk),
      DevTools.instrument()
    )
  : compose(applyMiddleware(thunk));

const reducer = require('./reducer').default;

function configureStore(initialState) {
  const store = createStore(reducer, initialState, enhancer);

  if (module.hot) {
    module.hot.accept('./reducer', () => store.replaceReducer(require('./reducer').default));
  }

  return store;
}

const store = configureStore();

export default store;
