import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { shuffle, slice } from 'lodash';

import { renderNumber } from '../utils/phone';

const propTypes = {
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      phoneNumber: PropTypes.string,
      state: PropTypes.string,
      rateCenter: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  onChosenNumber: PropTypes.func.isRequired,
  maxResultsCount: PropTypes.number,
  clientInfo: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    sampleSku: PropTypes.string,
    thumbnailURL: PropTypes.string,
  }),
};

const defaultProps = {
  maxResultsCount: null,
};

class AvailableNumberList extends Component {
  constructor(props) {
    super(props);

    this.getButtonStyle = this.getButtonStyle.bind(this);
    this.chosenNumber = this.chosenNumber.bind(this);
    this.renderAvailablePhoneNumbers =
      this.renderAvailablePhoneNumbers.bind(this);
  }

  getButtonStyle() {
    const { clientInfo } = this.props;
    let style = {};

    if (clientInfo && clientInfo.color) {
      style = { borderColor: clientInfo.color };
    }
    return style;
  }

  chosenNumber(number) {
    const { onChosenNumber } = this.props;

    onChosenNumber(number);
  }

  renderAvailablePhoneNumbers(numbers, onNumberSelected) {
    let list;

    if (!numbers) {
      list = null;
    } else {
      list = numbers.map((item, index) => (
        <a
          className={
            numbers.length <= 3
              ? 'PickNumber__box cell small-12'
              : 'PickNumber__box cell small-6'
          }
          style={this.getButtonStyle()}
          key={index}
          onClick={() => onNumberSelected(item.phoneNumber)}
        >
          {renderNumber(item.phoneNumber)}
        </a>
      ));
    }

    return (
      <div>
        <h4 className="u-margin-top-35px">
          These look like winners
        </h4>
        <div className="NumberPicker u-margin-top-35px">
          <div className="NumberPicker__container grid-x grid-padding-x align-center-middle">
            <div className="cell auto medium-10 large-7">
              <div className="grid-x grid-padding-x grid-margin-y grid-margin-x align-center-middle">
                {list}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { numbers, clientInfo, maxResultsCount } = this.props;

    return this.renderAvailablePhoneNumbers(
      maxResultsCount ? slice(shuffle(numbers), 0, maxResultsCount) : numbers,
      this.chosenNumber,
      clientInfo
    );
  }
}

AvailableNumberList.propTypes = propTypes;
AvailableNumberList.defaultProps = defaultProps;

export default AvailableNumberList;
