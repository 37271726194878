import axios from 'axios';
import { BASE_URL, getHumanScopeText } from '../constants';
import { hasError } from './error';
import { getBurnersList, receiveBurnersList, requesting, RECEIVE_BURNERS_LIST } from './general';
import { extractError } from '../utils/request';
import { fetchVerifyCode } from './verify';
import { removeItemFromArray } from '../utils/array';

// Actions
export const RECEIVE_CLIENT_INFO = 'burner-app/oauth/RECEIVE_CLIENT_INFO';
export const SET_OAUTH_METADATA = 'burner-app/oauth/SET_OAUTH_METADATA';
export const RECEIVE_HUMAN_FRIENDLY_SCOPES = 'burner-app/oauth/RECEIVE_HUMAN_FRIENDLY_SCOPES';
export const SELECT_BURNERS_FOR_OAUTH = 'burner-app/oauth/SELECT_BURNERS_FOR_OAUTH';
export const TOGGLE_BURNER_FOR_OAUTH = 'burner-app/oauth/TOGGLE_BURNER_FOR_OAUTH';
export const AUTHORIZE_SUCCESS = 'burner-app/oauth/AUTHORIZE_SUCCESS';

// Reducer
/**
 * State for the oauth page.
 * isUserSelectedBurners - the state params that toggle whether we should see
 */
export default function reducer(
  state = {
    selectedBurnerIds: [],
    confirmedBurnerSelection: false,
  },
  action
) {
  switch (action.type) {
    case SET_OAUTH_METADATA:
      return Object.assign({}, state, {
        phoneNumber: action.phoneNumber,
      });
    case RECEIVE_CLIENT_INFO:
      return Object.assign({}, state, {
        clientInfo: action.clientInfo,
      });
    case SELECT_BURNERS_FOR_OAUTH:
      return Object.assign({}, state, {
        selectedBurnerIds: action.selectedBurnerIds.length > 0 ? action.selectedBurnerIds : state.selectedBurnerIds, // selectedBurnerIds is optional
        confirmedBurnerSelection: true,
      });
    case TOGGLE_BURNER_FOR_OAUTH:
      return Object.assign({}, state, {
        selectedBurnerIds:
          state.selectedBurnerIds.indexOf(action.burnerId) >= 0
            ? removeItemFromArray(state.selectedBurnerIds, action.burnerId)
            : state.selectedBurnerIds.concat(action.burnerId),
      });
    case RECEIVE_HUMAN_FRIENDLY_SCOPES:
      return Object.assign({}, state, {
        humanFriendlyScopes: action.humanFriendlyScopes,
      });
    case AUTHORIZE_SUCCESS:
      return Object.assign({}, state, {
        authorizeCode: action.authorizeCode,
        authorizeState: action.authorizeState,
      });
    case RECEIVE_BURNERS_LIST:
      return Object.assign({}, state, {
        availableBurners: action.burners,
        selectedBurnerIds:
          action.burners && action.burners.length === 1
            ? action.burners.map((burner) => burner.id)
            : state.selectedBurnerIds, // if user only has one burner, then we set that as the burner for oauth (so we skip the picking burner screen)
      });
    default:
      return state;
  }
}

// Action Creators
export function receiveClientInfo(clientInfo) {
  return {
    type: RECEIVE_CLIENT_INFO,
    clientInfo,
  };
}

/**
 * Sets any metadata that can be passed in from the oauth paramaters
 */
export function setOauthMetadata(phoneNumber) {
  return {
    type: SET_OAUTH_METADATA,
    phoneNumber,
  };
}

/**
 * Called by the initial loading of the oauth page if user has passed in a burner_id initially
 */
export function selectBurnersForOauth(...burnerIds) {
  return {
    type: SELECT_BURNERS_FOR_OAUTH,
    selectedBurnerIds: burnerIds,
  };
}

/**
 * Action done by the user -- when burners is selected (for oauth).
 */
export function toggleBurnerSelect(burner) {
  return {
    type: TOGGLE_BURNER_FOR_OAUTH,
    burnerId: burner.id,
  };
}

function receiveAuthorize(data) {
  return {
    type: AUTHORIZE_SUCCESS,
    authorizeState: data.state,
    authorizeCode: data.code,
  };
}

export const sendVerifyCode = (number, authToken) => fetchVerifyCode(number, false, null, null, authToken);

/**
 * Fetches the oauth developer info that includes the picture and company name, etc.
 */
export function fetchClientInfo(clientId, errorRedirect) {
  const errorRedirectHash = { redirectBackAction: errorRedirect };

  return (dispatch) => {
    dispatch(requesting());
    return axios
      .get(`${BASE_URL}/applications`, {})
      .then((resp) => {
        try {
          const clientInfo = resp.data.find((app) => app.clientId === clientId);
          if (clientInfo) {
            dispatch(receiveClientInfo(clientInfo));
          } else {
            throw 'That Client ID does not exist.';
          }
        } catch (err) {
          dispatch(Object.assign({}, errorRedirectHash, hasError(extractError(err))));
        }
      })
      .catch((error) => {
        dispatch(Object.assign({}, errorRedirectHash, hasError(extractError(error), error.status)));
      });
  };
}

function getHumanFriendlyScopes(scopes, burnerNames) {
  const result = scopes.split(' ').map((scope) => getHumanScopeText(scope, burnerNames));

  return {
    type: RECEIVE_HUMAN_FRIENDLY_SCOPES,
    humanFriendlyScopes: result,
  };
}

/**
 * Returns a map of the oauth access scopes to the human readable format.
 */
export function getScopeDefinitions(authToken, userId, scopes, burnerIds) {
  const successFn = function (burners) {
    return (dispatch) => {
      dispatch(receiveBurnersList(burners));
      const burnerNames = burnerIds.map((burnerId) => {
        const found = burners.find((burner) => burner.id === burnerId);
        if (found) {
          return found.name;
        }

        return null;
      });

      dispatch(getHumanFriendlyScopes(scopes, burnerNames));
    };
  };

  return getBurnersList({ authToken, userId }, successFn);
}

/**
 * Calls our api server to authorize the user.
 */
export function authorize(authToken, userId, clientId, authorizeState, scopes, redirectUri, selectedBurnerIds, errorRedirect) {
  const errorRedirectHash = { redirectBackAction: errorRedirect };
  let url = `${BASE_URL}/oauth/authorize?user_id=${userId}&client_id=${clientId}&state=${authorizeState}&scope=${scopes}&approved_scopes=${scopes}&redirect_uri=${redirectUri}&response_type=code`;
  if (selectedBurnerIds && selectedBurnerIds.length > 0) {
    url += `&burner_ids=${selectedBurnerIds.join(',')}`;
  }

  return (dispatch) => {
    dispatch(requesting());
    return axios
      .request({
        method: 'post',
        url,
        headers: {
          Authentication: authToken,
        },
      })
      .then((resp) => {
        if (resp.data) {
          dispatch(receiveAuthorize(resp.data));
        } else {
          dispatch(Object.assign({}, errorRedirectHash, hasError('Sorry we are unable to authorize at the moment.')));
        }
      })
      .catch(() => {
        dispatch(Object.assign({}, errorRedirectHash, hasError('Sorry we are unable to authorize at the moment.')));
      });
  };
}
