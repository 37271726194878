import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { validateAreaCode } from '../../utils/phone';

const SUBSCRIBEPAGE = 'SubscribePage';

const propTypes = {
  headlineText: PropTypes.string,
  onAreaCodeEnter: PropTypes.func,
  template: PropTypes.string,
};

/**
 * Area code input box for the subscription purchase page (aka. the premium-3 page).
 */
class AreaCodeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaCode: '',
      errorMessage: null,
    };

    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.areaCodePickerField = this.areaCodePickerField.bind(this);
    this.renderErrorSection = this.renderErrorSection.bind(this);
    this.renderSearchBox = this.renderSearchBox.bind(this);
    this.renderPicker = this.renderPicker.bind(this);
    this.renderPickerAlt = this.renderPickerAlt.bind(this);

    $(':input[type=number]').on('mousewheel', () => {
      $(this).blur();
    });
  }

  handlePhoneChange(values) {
    this.setState(() => ({
      areaCode: values.value,
      errorMessage: null,
    }));
  }

  handleSubmit(event) {
    const { areaCode } = this.state;
    const { onAreaCodeEnter } = this.props;

    event.preventDefault();
    const errorMessage = validateAreaCode(areaCode);
    if (errorMessage) {
      this.setState(() => ({
        errorMessage,
      }));
      return;
    }
    onAreaCodeEnter(areaCode);
  }

  areaCodePickerField() {
    const { areaCode } = this.state;

    return (
      <div>
        <NumberFormat
          format="###"
          type="tel"
          className="area-code Input__field"
          onValueChange={this.handlePhoneChange}
          value={areaCode}
          placeholder="XXX"
        />
      </div>
    );
  }

  renderErrorSection() {
    const { errorMessage } = this.state;

    if (!errorMessage) {
      return null;
    }

    return <div className="error error-msg center">{errorMessage}</div>;
  }

  renderSearchBox() {
    const { template } = this.props;
    const picker =
      template === SUBSCRIBEPAGE ? this.renderPicker() : this.renderPickerAlt();

    return (
      <div className="u-margin-top-35px grid-x align-center">{picker}</div>
    );
  }

  renderPicker() {
    return (
      <div className="cell large-5 small-12">
        <form onSubmit={this.handleSubmit}>
          <div className="align-bottom Form__inputWrapper u-margin-top-35px grid-x grid-padding-x align-center">
            <label
              htmlFor="AreaCode"
              className="text-left Form__fieldWrapper cell auto"
            >
              <span className="Input__label">Area code</span>
              {this.areaCodePickerField()}
            </label>
            <div className="Form__submitWrapper cell shrink">
              <button className="button button--notRounded" type="submit">
                Give me my number
              </button>
            </div>
          </div>
          <div className="text-center Form__outputWrapper u-margin-top-35px grid-x align-center">
            <div className="cell large-8">{this.renderErrorSection()}</div>
          </div>
        </form>
      </div>
    );
  }

  renderPickerAlt() {
    return (
      <div className="small-12 small-offset-0 medium-6 medium-offset-3 large-4 large-offset-4 columns number-input-container">
        <form onSubmit={this.handleSubmit}>
          {this.areaCodePickerField()}
          <button className="button expanded w-button" type="submit">
            Find Numbers
          </button>
          <div className="centered">
            <em>We currently support US and Canadian numbers</em>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return this.renderSearchBox();
  }
}

AreaCodeInput.propTypes = propTypes;

export default AreaCodeInput;
