import React from 'react';
import PropTypes from 'prop-types';

import Features from 'components/purchase/Features';

const propTypes = {
  discountPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  selectPlan: PropTypes.func.isRequired,
  subscriptionPlan: PropTypes.shape({
    sku: PropTypes.string,
    price: PropTypes.string,
    numLines: PropTypes.number,
    isYearly: PropTypes.bool,
  }).isRequired,
  toMonthlyPrice: PropTypes.func,
};

const defaultProps = {
  discountPercent: false,
  toMonthlyPrice: () => {},
};
function SubscriptionButton({ selectPlan, subscriptionPlan, toMonthlyPrice, discountPercent }) {
  return (
    <div className="cell small-12 auto large-6">
      <button
        className="SubscriptionPlan__option"
        type="button"
        onClick={() => {
          selectPlan(subscriptionPlan);
        }}
      >
        {!subscriptionPlan.isYearly ? (
          <div className="SubscriptionPlan__box">
            <div className="SubscriptionPlanBox__period">Monthly</div>
            <div className="SubscriptionPlanBox__price">
              ${subscriptionPlan.price}/mo
            </div>
            <div className="SubscriptionPlanBox__priceBillingInfo">
              Billed monthly at ${subscriptionPlan.price}/mo
            </div>
            <Features />
            <div className="button button--fullWidth button--fillY button--notRounded">
              Get Burner
            </div>
          </div>
        ) : (
          <div className="SubscriptionPlan__box SubscriptionPlan__box--featured">
            <div className="SubscriptionPlanBox__period">Yearly</div>
            <div className="SubscriptionPlanBox__price">
              ${toMonthlyPrice(subscriptionPlan.price)}/mo
            </div>
            <div className="SubscriptionPlanBox__priceBillingInfo">
              Billed yearly at ${subscriptionPlan.price}/yr
            </div>
            <Features />
            <div className="button button--fullWidth button--fillY button--notRounded">
              Get Burner
            </div>
            <div className="SubscriptionPlanBox__highlightBadge">
              Save {discountPercent || '20'}%
            </div>
          </div>
        )}
      </button>
    </div>
  );
}

SubscriptionButton.propTypes = propTypes;
SubscriptionButton.defaultProps = defaultProps;

export default SubscriptionButton;
