import React from 'react';
import { US, AU } from '../constants';
import PhoneFormat from 'phoneformat.js';
const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  value === '' ||
  value === 'undefined';

export function isValidNumber(number) {
  return (
    PhoneFormat.isValidNumber(number, US) ||
    PhoneFormat.isValidNumber(number, AU)
  );
}

/**
 * Formats a number to e164. Assumes that the phone is either a valid US/CA or AU number
 */
export function formatE164(number) {
  if (!isEmpty(number)) {
    if (PhoneFormat.isValidNumber(number, US)) {
      return PhoneFormat.formatE164(US, number);
    }

    return PhoneFormat.formatE164(AU, number);
  }
}

/**
 * Gets the country of a number.
 * @param number
 */
export function getCountry(number) {
  if (!isEmpty(number)) {
    if (PhoneFormat.isValidNumber(number, US)) {
      return US;
    } else if (PhoneFormat.isValidNumber(number, AU)) {
      return AU;
    }
  }
}

export function formatLocal(number) {
  if (!isEmpty(number)) {
    // Seems like there's a bug in phoneformat.js' formatLocal function
    // Tested with 780 and 725 and the latter just ignores the +1 US code
    // Let's force the country code preffix
    number = PhoneFormat.formatNumberForMobileDialing(
      getCountry(number),
      number
    );
    number = number.split(' ');
    return PhoneFormat.formatLocal(getCountry(number), number[0] + number[1]);
  }
  return '';
}

export function renderLocalNumber(number) {
  if (!isEmpty(number)) {
    number = formatLocal(number).split(' '); // remove the country code
    number = number[1].split('-');
    return `(${number[0]}) ${number[1]}-${number[2]}`;
  }
}

export function removeCountryCode(number) {
  if (!isEmpty(number)) {
    number = formatLocal(number).split(' '); // remove the country code
    return number[1]
  }
}

export function renderNumber(number) {
  if (!isEmpty(number)) {
    number = formatLocal(number).split(' '); // remove the country code
    number = number[1].split('-'); // split into blocks
    return (
      <div>
        <span className="PickNumber__areaCode">({number[0]})</span> {number[1]}-
        {number[2]}
      </div>
    );
  }
}

export function validateAreaCode(areaCode) {
  if (!areaCode || areaCode.length !== 3) {
    return 'Area code must be 3 digits.';
  } else if (areaCode.slice(0, 1) === '0') {
    return 'Please enter a valid US or Canadian area code.';
  }
}
