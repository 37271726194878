import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  clientInfo: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    sampleSku: PropTypes.string,
    thumbnailURL: PropTypes.string,
  }),
  onClick: PropTypes.func,
  className: PropTypes.string.isRequired,
  anchorText: PropTypes.string.isRequired,
  href: PropTypes.string,
  target: PropTypes.string,
};

function OauthAwareAnchor({
  clientInfo = null,
  href = null,
  target = null,
  onClick = () => {},
  className,
  anchorText,
}) {
  let style = {};

  if (clientInfo && clientInfo.color) {
    style = { color: clientInfo.color };
  }

  return (
    <a href={href ? href : '#'} target={target} onClick={onClick} className={className}>
      <span style={style}>{anchorText}</span>
    </a>
  );
}

OauthAwareAnchor.propTypes = propTypes;

export default OauthAwareAnchor;
