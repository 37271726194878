import React, { Component } from 'react';
class Header extends Component {
  renderBurnerImage(logo) {
    return (
      <a href="https://www.burnerapp.com/">
        <img src={logo} alt="Burner" title="Burner" />
      </a>
    );
  }

  render() {
    const logo = require('images/logo-wordmark.png');
    const GoogleBadge = require('images/play-store-badge.png');
    const AppleBadge = require('images/app-store-badge.png');

    return (
      <header className="Header align-center grid-x">
        <div className="Header__logo cell small-4 medium-3">
          {this.renderBurnerImage(logo)}
        </div>
        <div className="align-right-middle cell small-8 medium-9">
          <div className="NavMenu align-right">
            <div className="menu-container">
              <div
                className="title-bar align-right text-right hide-for-medium"
                data-responsive-toggle="responsive-menu"
                data-hide-for="medium"
              >
                <button
                  className="menu-icon"
                  type="button"
                  data-toggle="responsive-menu"
                />
                <div className="title-bar-title"></div>
              </div>

              <div className="top-bar" id="responsive-menu">
                <ul
                  className="vertical medium-horizontal dropdown menu"
                  data-dropdown-menu
                >
                  <li>
                    <a href="https://www.burnerapp.com/how-burner-works">
                      How Burner works
                    </a>
                  </li>
                  <li>
                    <a href="https://www.burnerapp.com/pricing">
                      Plans & pricing
                    </a>
                  </li>
                  <li>
                    <a href="https://www.burnerapp.com/blog">Blog</a>
                  </li>
                  <li>
                    <a
                      href="https://app.adjust.com/oc0osf6_8fmse8n?fallback=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&utm_source=burnerapp&utm_medium=cta_header&utm_campaign=web-app&redirect_macos=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&utm_source=burnerapp&utm_medium=cta_header&utm_campaign=web-app"
                      className="button button--notRounded"
                    >
                      Get Burner
                    </a>
                  </li>
                  <li>
                    <div className="Header__downloadBadges hide-for-medium">
                      <a href="https://app.adjust.com/gegg2hg?fallback=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&amp;utm_source=burnerapp&amp;utm_medium=app_store&amp;utm_campaign=confirmation-page&amp;redirect_macos=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&amp;utm_source=burnerapp&amp;utm_medium=app_store&amp;utm_campaign=confirmation-page">
                        <img
                          src={AppleBadge}
                          loading="lazy"
                          alt="Download on the App Store"
                        />
                      </a>
                      <a href="https://app.adjust.com/9j9egjp?fallback=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&amp;utm_source=burnerapp&amp;utm_medium=play_store&amp;utm_campaign=confirmation-page&amp;redirect_macos=http%3A%2F%2Fapp.burnerapp.com%2Fpremium-3&amp;utm_source=burnerapp&amp;utm_medium=play_store&amp;utm_campaign=confirmation-page">
                        <img
                          src={GoogleBadge}
                          loading="lazy"
                          alt="Get it on Google Play"
                        />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
