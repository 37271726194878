export function isBurnerInSubscription(burner, subscriptions) {
  return (
    burner &&
    subscriptions &&
    subscriptions[0] &&
    subscriptions[0].burnerIds.findIndex((burnerId) => burnerId === burner.id) >= 0
  );
}

export function isSubscriptionExpired(sub) {
  if (!sub) {
    return false;
  }
  return new Date().getTime() > sub.renewalDate;
}

export function isSubscriptionCancelled(sub) {
  if (!sub) {
    return false;
  }
  return new Date().getTime() > sub.cancellationDate && !isSubscriptionExpired(sub);
}

export function isStripeSubscription(sub) {
  if (!sub) {
    return false;
  }

  if (!sub.store) {
    return false;
  }

  return sub.store.toLowerCase() === 'stripe';
}

export function canAssignBurnerToSubscription(rules, subscriptions) {
  if (!subscriptions || !subscriptions[0]) {
    return false;
  }

  const subscriptionsMetadata = rules.subscriptions;
  const totalBurnersAllowedInPeriod = subscriptionsMetadata.find((i) => i.sku === subscriptions[0].sku)
    .totalBurnersAllowedInPeriod;
  const totalConcurrentBurners = subscriptionsMetadata.find((i) => i.sku === subscriptions[0].sku)
    .totalConcurrentBurners;

  return (
    subscriptions[0].burnersAssignedInPeriod < totalBurnersAllowedInPeriod &&
    subscriptions[0].burnerIds.length < totalConcurrentBurners
  );
}

export function toMonthlyPrice(price) {
  let monthlyPrice = price / 12;
  monthlyPrice = Math.round(monthlyPrice * Math.pow(10, 2)) / Math.pow(10, 2);

  // round values to nearest .99 if the float value is within .05 of the nearest int
  // e.g. we'd round 4.0 or 3.97 to 3.99 but not 11.68 to 11.67```
  let adjustedPrice = monthlyPrice;
  const distanceToInt = 1 - Math.abs(monthlyPrice - Math.floor(monthlyPrice));
  if (distanceToInt < 0.05 || distanceToInt === 1) {
    adjustedPrice = Math.ceil(monthlyPrice) - 0.01;
  }
  return adjustedPrice;
}
