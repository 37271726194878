import { CURRENT_USER } from '../constants.js';
import cookie from 'react-cookie';

export function getCurrentUser() {
  const item = cookie.load(CURRENT_USER);
  if (!item) {
    return null;
  }
  return item;
}
