import React, { Component } from 'react';
import { Router, IndexRoute, Route, browserHistory, applyRouterMiddleware } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import store from './lib/store';

const history = syncHistoryWithStore(browserHistory, store);

import App from 'pages/App';
import SignupPage from 'pages/SignupPage';
import VerifyPage from 'pages/VerifyPage';
import ChooseNumberPage from 'pages/ChooseNumberPage';
import DashboardPage from 'pages/DashboardPage';
import CPlusSuccess from 'pages/CPlusSuccess';
import CPlusVerifyPage from 'pages/CPlusVerifyPage';
import SubscribePage from 'pages/SubscribePage';
import PurchasePageContainer from './containers/PurchasePageContainer';
import SearchPageContainer from './containers/SearchPageContainer';
import PickNumberPageContainer from './containers/PickNumberPageContainer';
import BurnerDetailsPage from 'pages/BurnerDetailsPage';
import OauthPage from 'pages/OauthPage';
import OauthErrorPage from 'pages/OauthErrorPage';
import ApplicationsPage from 'pages/ApplicationsPage';
import ChooseSubscriptionPageContainer from './containers/ChooseSubscriptionPageContainer';
import ConfirmationPageContainer from './containers/ConfirmationPageContainer';
import { CUSTOM_WEB_PAGEVIEW } from './constants.js';
class Routes extends Component {
  handleOnUpdate() {
    const logPageView = () => {
      dataLayer.push({
        event: CUSTOM_WEB_PAGEVIEW,
        virtualPageURL: window.location.pathname,
      });
    };

    logPageView();
  }

  render() {
    return (
      <Router
        history={history}
        onUpdate={this.handleOnUpdate}
        render={applyRouterMiddleware()}
      >
        <Route path="/" component={App}>
          <IndexRoute component={SignupPage} />
          <Route path="premium-3" component={SubscribePage} />
          <Route
            path="find-temporary-phone-number(/:areaCode)"
            component={SearchPageContainer}
          />
          <Route
            path="areacode/:areaCode"
            component={PickNumberPageContainer}
          />
          <Route
            path="subscription-options"
            component={ChooseSubscriptionPageContainer}
          />
          <Route path="checkout" component={PurchasePageContainer} />
          <Route path="confirmation" component={ConfirmationPageContainer} />
          <Route path="signup" component={SignupPage} />
          <Route path="verify/:phone" component={VerifyPage} />
          <Route path="cplus-verify/:phone" component={CPlusVerifyPage} />
          <Route path="cplussuccess" component={CPlusSuccess} />
          <Route path="chooseNumber" component={ChooseNumberPage} />
          <Route path="dashboard" component={DashboardPage} />
          <Route path="burner/:burnerId" component={BurnerDetailsPage} />
          <Route path="oauth/authorize" component={OauthPage} />
          <Route path="oauth/error" component={OauthErrorPage} />
          <Route path="applications" component={ApplicationsPage} />
          <Route path="*" component={SubscribePage} />
        </Route>
      </Router>
    );
  }
}

export default Routes;
