import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import * as actionCreators from '../../ducks/successModal';

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  message: PropTypes.string,
  showSuccess: PropTypes.bool,
  title: PropTypes.string,
};

const defaultProps = {
  message: null,
  showSuccess: false,
  title: null,
};

class SuccessModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  componentDidUpdate(prevProps) {
    const { showSuccess } = this.props;

    if (prevProps.showSuccess === showSuccess) {
      return;
    }
    if (showSuccess) {
      this.openModal();
    } else if (!showSuccess) {
      this.closeModal();
    }
  }

  openModal() {
    this.setState(() => ({
      modalIsOpen: true,
    }));
  }

  closeModal() {
    this.setState(() => ({
      modalIsOpen: false,
    }));
  }

  close() {
    const { closeModal } = this.props;

    this.closeModal();
    closeModal();
  }

  renderMessageHtml(html) {
    return { __html: html };
  }

  render() {
    const { modalIsOpen } = this.state;
    const { title, message } = this.props;

    return (
      <Modal
        contentLabel="Error Modal"
        isOpen={modalIsOpen}
        onRequestClose={this.close}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        className="burner-modal"
        overlayClassName="burner-modal-overlay"
      >
        <div id="successModal">
          <div className="row">
            <div className="large-12 columns">
              <div>
                <h2 className="renewal-title">{title || 'Success!'}</h2>
                <div className="renewal-message" dangerouslySetInnerHTML={this.renderMessageHtml(message)} />
                <button type="submit" onClick={this.close} className="flat button selection-button submit">
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

SuccessModal.propTypes = propTypes;
SuccessModal.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    message: state.successModal.message,
    showSuccess: state.successModal.showSuccess,
    title: state.successModal.title,
  };
}

const mapDispatchToProps = actionCreators;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuccessModal);
