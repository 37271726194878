import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import OauthAwareHeader from '../components/oauth/OauthAwareHeader';
import { renderNumber } from '../utils/phone';
import AppButtons from '../components/AppButtons';
import Footer from '../components/oauth/Footer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import { OAUTH_REDIRECT_PARAMS } from '../constants';
import '!style-loader!css-loader!sass-loader!../../styles/cplus-success.scss';

class CPlusSuccess extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  redirect() {
    const oauthParams = localStorage.getItem(OAUTH_REDIRECT_PARAMS);
    const redirectQuery = `${oauthParams}`;
    const queryValues = queryString.parse(redirectQuery);
    const { state, redirect_uri } = queryValues;
    const { authorizeCode } = this.props;
    document.location = `${redirect_uri}?code=${authorizeCode}&state=${state}`;
  }

  renderExpiresText() {
    const {
      burner: { expirationDate },
    } = this.props;
    return (
      <div className="columns small-12 medium-8 medium-offset-2">
        <div className="columns small-6">
          <p className="bold">{'Expires'}</p>
        </div>
        <div className="columns small-6">
          <p className="text-right">{moment(expirationDate).calendar()}</p>
        </div>
      </div>
    );
  }

  render() {
    const { burner, clientInfo } = this.props;
    if (!burner) {
      this.context.router.push('/dashboard');
      return null;
    }
    return (
      <div className="row main-app" id="cplus-container">
        <div className="root-container">
          <div className="main-container">
            <div style={{ margin: '0 4em' }}>
              <div>
                <OauthAwareHeader clientInfo={clientInfo} />
                <div className="row" style={{ marginBottom: '1.5em' }}>
                  <div
                    className="columns small-12 medium-8 medium-offset-2 text-center"
                    style={{
                      fontSize: '1.5em',
                    }}
                  >
                    Download the Burner App to start using your new number
                  </div>
                </div>
                <div className="row">
                  <div className="columns small-12 medium-8 medium-offset-2">
                    <div className="columns small-6">
                      <div
                        className="rounded"
                        style={{
                          padding: '15px 30px 15px 30px',
                          fontSize: '1em',
                          boxShadow: 'inset 0 0 1px 1px #ececec',
                        }}
                      >
                        {renderNumber(burner.phoneNumberId)}
                      </div>
                    </div>
                    <div className="columns small-6">
                      <CopyToClipboard text={burner.phoneNumberId.substring(1)}>
                        <button className="button" style={{ float: 'right' }}>
                          Copy
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: '1.5em' }}>
                  {this.renderExpiresText()}
                </div>
                <div className="row">
                  <div className="small-12 medium-8 medium-offset-2" id="cplus-app-buttons">
                    <AppButtons />
                  </div>
                </div>
                <div className="row text-center" style={{ marginTop: '1em' }}>
                  <button style={{ textDecoration: 'underline', color: '#5b5b96' }} onClick={() => this.redirect()}>
                    No Thanks
                  </button>
                </div>
                <Footer clientInfo={clientInfo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clientInfo: state.oauthPage.clientInfo,
    burner: state.chooseNumberPage.burner,
    authorizeCode: state.oauthPage.authorizeCode,
  };
}

export default connect(
  mapStateToProps,
  null
)(CPlusSuccess);
