export function removeNulls(obj) {
  for (const k in obj) {
    if (obj.hasOwnProperty(k) && !obj[k]) {
      delete obj[k];
    }
  }
  return obj;
}

export function calcPriceWithDiscount(subtotal, discountAmount) {
  if (discountAmount) {
    return (subtotal * ((100 - discountAmount) / 100)).toFixed(2);
  }

  return subtotal;
}
