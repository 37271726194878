import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

import { CUSTOM_WEB_EVENT, FIND_NUMBER } from '../../constants';
import * as actionCreators from '../../ducks/subscribe';
import { validateAreaCode } from '../../utils/phone';

const propTypes = {
  areaCode: PropTypes.string.isRequired,
  requestNumber: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
};

class AreaCodePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaCode: this.props.areaCode,
      errorMessage: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.findNumbers = this.findNumbers.bind(this);
  }

  componentWillMount() {
    const { areaCode } = this.state;

    if (areaCode) {
      const errorMessage = validateAreaCode(areaCode);
      if (errorMessage) {
        this.setState(() => ({
          errorMessage,
        }));
        return;
      }
    }
  }

  handleInputChange(values) {
    this.setState(() => ({
      areaCode: values.value,
    }));
  }

  handleSubmit(event) {
    const { areaCode } = this.state;
    const { router } = this.context;
    const errorMessage = validateAreaCode(areaCode);

    event.preventDefault();
    if (errorMessage) {
      this.setState(() => ({
        errorMessage,
      }));
      return;
    }

    this.findNumbers();
    router.push(`/areacode/${areaCode}`);
  }

  findNumbers() {
    const { areaCode } = this.state;
    const { requestNumber } = this.props;

    dataLayer.push({
      event: CUSTOM_WEB_EVENT,
      customWebEventName: FIND_NUMBER,
      customWebEventAction: areaCode,
    });

    requestNumber(areaCode);
  }

  render() {
    const { errorMessage } = this.state;

    return (
      <div className="u-margin-top-35px grid-x grid-padding-x align-center-middle">
        <div className="cell large-5 small-12">
          <form onSubmit={this.handleSubmit}>
            <div className="align-bottom Form__inputWrapper u-margin-top-35px grid-x grid-padding-x align-center">
              <label
                htmlFor="AreaCode"
                className="text-left Form__fieldWrapper cell auto"
              >
                <span className="Input__label">Area code</span>
                <NumberFormat
                  autoFocus
                  format="###"
                  type="tel"
                  className="area-code Input__field"
                  onValueChange={this.handleInputChange}
                  placeholder="XXX"
                />
              </label>
              <div className="Form__submitWrapper cell shrink">
                <button className="button button--notRounded" type="submit">
                  Find Numbers
                </button>
              </div>
            </div>
            <div className="text-center Form__outputWrapper u-margin-top-35px grid-x align-center">
              <div className="cell large-8">
                <em>We currently support US and Canadian numbers</em>
              </div>
            </div>
            {errorMessage && (
              <div className="text-center Form__outputWrapper u-margin-top-35px grid-x align-center">
                <div className="cell large-8">{errorMessage}</div>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

AreaCodePicker.propTypes = propTypes;
AreaCodePicker.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapDispatchToProps = actionCreators;

export default connect(null, mapDispatchToProps)(AreaCodePicker);
