import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import * as actionCreators from '../../ducks/general';

const propTypes = {
  clearUserInfo: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.isOnPage = this.isOnPage.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    $(document).foundation();
  }

  isOnPage(page) {
    const { currentPage } = this.props;

    return currentPage === page;
  }

  logout() {
    const { clearUserInfo } = this.props;

    clearUserInfo();
  }

  render() {
    const logo = require('images/logo-wordmark.png');

    return (
      <div>
        <div id="header">
          <div className="logo-container">
            <img src={logo} alt="Burner" title="Burner" />

            <div className="menu-container">
              <div className="title-bar " data-responsive-toggle="responsive-menu" data-hide-for="large" data-toggle="">
                <button className="menu-icon" type="button" data-toggle="" />
                <div className="title-bar-title">Menu</div>
              </div>

              <div className="top-bar" id="responsive-menu">
                <ul className="vertical medium-horizontal dropdown menu" data-dropdown-menu>
                  <li>
                    <a href="http://support.burnerapp.com">Support</a>
                  </li>
                  <li className={this.isOnPage('DashboardPage') ? 'active' : ''}>
                    <Link to="/dashboard">Account</Link>
                  </li>
                  <li>
                    <a onClick={this.logout}>Logout</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = propTypes;

const mapDispatchToProps = actionCreators;

export default connect(
  null,
  mapDispatchToProps
)(Header);
