import React from 'react';
import PropTypes from 'prop-types';

import Header from '../components/common/Header';
import '!style-loader!css-loader!sass-loader!../../styles/oauth.scss';
import '!style-loader!css-loader!sass-loader!../../styles/oauth-responsive.scss';

const propTypes = {
  location: PropTypes.object.isRequired,
};

function OauthErrorPage({ location }) {
  const error = location.query.error;

  return (
    <div className="row main-app">
      <div className="large-12 columns">
        <div id="oauth-container">
          <Header />
          <div className="">
            <h4 className="error">{error}</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

OauthErrorPage.propTypes = propTypes;
OauthErrorPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default OauthErrorPage;
