import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  autoFocus: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  maxLength: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

const defaultProps = {
  name: null,
  placeholder: null,
};

class NumberInput extends Component {
  componentDidMount() {
    const { id } = this.props;

    $(`#${id}`).on('mousewheel', (event) => {
      event.preventDefault();
    });
  }

  render() {
    const { autoFocus, className, id, maxLength, name, onChange, placeholder } = this.props;

    return (
      <input
        autoFocus={autoFocus}
        className={className}
        id={id}
        maxLength={maxLength}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type="text"
      />
    );
  }
}

NumberInput.propTypes = propTypes;
NumberInput.defaultProps = defaultProps;

export default NumberInput;
