import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  clientInfo: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    sampleSku: PropTypes.string,
    thumbnailURL: PropTypes.string,
  }).isRequired,
};

function HeaderPartnerOauth({ clientInfo }) {
  return (
    <div>
      <div id="header-center">
        <div>
          <img className="partner-header-img" src={clientInfo.thumbnailURL} alt="Client Auth" />
        </div>
      </div>
    </div>
  );
}

HeaderPartnerOauth.propTypes = propTypes;

export default HeaderPartnerOauth;
