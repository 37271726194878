import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import Features from 'components/purchase/Features';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';

import {
  createValidator,
  required,
  integer,
  validateEmail,
  validateCvc,
  validatePhoneNumber,
  validateExpMonth,
  validateExpYear,
  validateCreditCard,
  validateZip,
} from '../../utils/validation';

const scrollToElement = require('scroll-to-element');

const renderInput = (field) => (
  <div>
    <input
      {...field.input}
      type={field.type}
      id={field.id}
      onKeyPress={field.onKeyPress}
      placeholder={field.placeholder}
    />
    {field.meta.touched && field.meta.error && (
      <span className="error">{field.meta.error}</span>
    )}
  </div>
);

const renderNumberFormat = (field) => (
  <div>
    <NumberFormat
      {...field.input}
      type={field.type}
      id={field.id}
      format={field.numberFormat}
      placeholder={field.placeholder}
      allowLeadingZeros={field.allowLeadingZeros}
    />
    {field.meta.touched && field.meta.error && (
      <span className="error">{field.meta.error}</span>
    )}
  </div>
);

class SubscribeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subtotal: this.getSubscriptionPrice(),
      userSubmittedForm: false,
    };

    this.getSubscriptionPrice = this.getSubscriptionPrice.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.renderAmountCharged = this.renderAmountCharged.bind(this);
    this.handleNameFields = this.handleNameFields.bind(this);

    const { freeTrialLength, isYearly, numLines } = this.props;
    this.isFreeTrial = freeTrialLength !== undefined;
    this.isYearly = isYearly;
    this.numLines = numLines;
  }

  getSubscriptionPrice() {
    const { price } = this.props;

    return price;
  }

  handleNameFields(event) {
    // Don't allow numbers to be input into the name field. JS event.charCodes 48 through 57 are the 0 through 9 keys.
    if (event.charCode >= 48 && event.charCode <= 57) {
      event.preventDefault();
    }
  }

  handleUserSubmitForm() {
    this.setState(() => ({
      userSubmittedForm: true,
    }));
  }

  submitHandler() {
    const { userSubmittedForm } = this.state;
    const { onSubmitSubscribeForm } = this.props;
    const event = this;

    if (event.props.submitFailed && userSubmittedForm) {
      scrollToElement(this.refs[Object.keys(event.props.errors)[0]], {
        duration: 1000,
      });
      this.setState(() => ({
        userSubmittedForm: false,
      }));
    }

    onSubmitSubscribeForm(event);
  }

  renderAmountCharged() {
    const { subtotal } = this.state;
    let totalChargedToday;

    if (subtotal === 0) {
      totalChargedToday = '$0.00';
    } else {
      totalChargedToday = `$${subtotal}${this.isYearly ? '/yr' : '/mo'}`;
    }

    return totalChargedToday;
  }

  render() {
    const { submitFailed, invalid, handleSubmit, submitting } = this.props;

    return (
      <div className="grid-x align-center-middle grid-padding-x u-margin-top-35px">
        <div className="cell small-11 large-6">
          <form
            onSubmit={handleSubmit(this.submitHandler)}
            id="contact-form"
            className="SubscriptionCheckout__form validate custom-form subscribe-form"
          >
            <h4>Account details</h4>
            <div className="grid-x grid-padding-x">
              <div className="cell large-6 small-12">
                <label htmlFor="firstName" ref="firstName">
                  First Name
                  <Field
                    name="firstName"
                    component={renderInput}
                    onKeyPress={this.handleNameFields}
                    placeholder="First Name"
                    id="firstName"
                    type="text"
                  />
                </label>
              </div>
              <div className="cell large-6 small-12">
                <label htmlFor="lastName" ref="lastName">
                  Last Name
                  <Field
                    name="lastName"
                    component={renderInput}
                    onKeyPress={this.handleNameFields}
                    placeholder="Last Name"
                    id="lastName"
                    type="text"
                  />
                </label>
              </div>
            </div>
            <div className="grid-x">
              <div className="small-12 cell">
                <label htmlFor="email" ref="email">
                  Email
                  <Field
                    name="email"
                    component={renderInput}
                    placeholder="Email"
                    id="email"
                    type="text"
                  />
                </label>
              </div>
            </div>
            <div className="grid-x">
              <div className="small-12 cell">
                <label htmlFor="phoneNumber" ref="phoneNumber">
                  Phone Number
                  <Field
                    name="phoneNumber"
                    component={renderNumberFormat}
                    numberFormat="(###) ###-####"
                    id="phoneNumber"
                    type="tel"
                  />
                </label>
              </div>
            </div>
            <h4 className="u-margin-top-35px">Billing Information</h4>
            <div className="grid-x">
              <div className="small-12 cell">
                <label htmlFor="creditCard" ref="creditCard">
                  Card Number
                  <Field
                    name="creditCard"
                    component={renderNumberFormat}
                    allowLeadingZeros
                    numberFormat="#### #### #### ####"
                    type="tel"
                    placeholder="Credit Card"
                    id="creditCard"
                  />
                </label>
              </div>
            </div>
            <div className="grid-x grid-padding-x">
              <div className="large-4 small-12 cell">
                <label htmlFor="expMonth" ref="expMonth">
                  Exp Month
                  <Field
                    name="expMonth"
                    component={renderNumberFormat}
                    allowLeadingZeros
                    numberFormat="##"
                    type="tel"
                    placeholder="MM"
                    id="expMonth"
                  />
                </label>
              </div>
              <div className="large-4 small-12 cell">
                <label htmlFor="expYear" ref="expYear">
                  Exp Year
                  <Field
                    name="expYear"
                    component={renderNumberFormat}
                    numberFormat="####"
                    type="tel"
                    placeholder="YYYY"
                    id="expYear"
                  />
                </label>
              </div>
              <div className="large-4 small-12 cell">
                <label htmlFor="cvc" ref="cvc">
                  Security Code
                  <Field
                    name="cvc"
                    component={renderNumberFormat}
                    allowLeadingZeros
                    id="cvc"
                    placeholder="CVC"
                    id="cvc"
                  />
                </label>
              </div>
            </div>
            <div className="grid-x">
              <div className="small-12 cell">
                <label htmlFor="zipCode" ref="zipCode">
                  Billing Zip Code
                  <Field
                    name="zipCode"
                    component={renderInput}
                    type="text"
                    minLength="5"
                    maxLength="7"
                    placeholder="Zip Code"
                    id="zipCode"
                  />
                </label>
              </div>
            </div>
            <div className="u-margin-top-35px grid-x text-center align-center-middle">
              <div className="small-12 cell">
                <div className="SubscriptionCheckout__planType strong">
                  Burner {this.isYearly ? 'Annual' : 'Monthly'} Subscription
                </div>
                <div className="SubscriptionPlanBox__price">
                  {this.renderAmountCharged()}
                </div>
                <div className="u-list-2-columns">
                  <Features />
                </div>
              </div>
            </div>
            <div className="grid-x text-center align-center-middle">
              <div className="large-12 small-12 cell">
                {invalid && submitFailed && (
                  <div id="form-error-msg" className="Error__text">
                    <span id="warning-text-container">
                      Looks like some fields are missing or have incorrect info.
                      Double check them above.
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="grid-x text-center align-center-middle">
              <div className="large-8 small-12 cell">
                <button
                  type="submit"
                  onClick={() => this.handleUserSubmitForm()}
                  className="button submit expanded"
                  disabled={submitting}
                >
                  {this.isFreeTrial ? 'Start Free Trial' : 'Subscribe'}
                </button>
                <div className="Subscription__cancelAnytime">
                  Cancel anytime
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="grid-x text-center align-center-middle u-margin-top-35px">
          <div className="large-7 small-12 cell">
            <p className="Subscription__tosDisclaimer">
              By continuing, you acknowledge that you agree to our terms of
              service and privacy policy. Subscription auto-renews at{' '}
              {this.renderAmountCharged()}.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

SubscribeForm.propTypes = {
  ...propTypes, // Props from redux form
  freeTrialLength: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  isYearly: PropTypes.bool.isRequired,
  numLines: PropTypes.number.isRequired,
  onSubmitSubscribeForm: PropTypes.func.isRequired,
  price: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
};

SubscribeForm.defaultProps = {
  freeTrialLength: undefined,
};

const mapStateToProps = function mapStateToProps(state) {
  return {
    freeTrialLength: state.subscribePage.freeTrialLength,
    isYearly: state.subscribePage.isYearly,
    numLines: state.subscribePage.numLines,
    price: state.subscribePage.price,
  };
};

SubscribeForm = reduxForm({
  form: 'subscribe',
  validate: createValidator({
    firstName: [required],
    lastName: [required],
    email: [required, validateEmail],
    phoneNumber: [required, validatePhoneNumber],
    creditCard: [required, validateCreditCard],
    expMonth: [required, validateExpMonth],
    expYear: [required, validateExpYear],
    cvc: [required, integer, validateCvc],
    zipCode: [required, validateZip],
  }),
})(SubscribeForm);

export default connect(mapStateToProps)(SubscribeForm);
