import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import * as actionCreators from '../../ducks/dashboard';
import { renderLocalNumber } from '../../utils/phone';
import { isBurnerInSubscription } from '../../utils/subscription';
import { isUnlimited } from '../../utils/burner';

const propTypes = {
  burners: PropTypes.arrayOf(
    PropTypes.shape({
      callerIdEnabled: PropTypes.bool,
      remainingTexts: PropTypes.number,
      name: PropTypes.string,
      expirationDate: PropTypes.number,
      phoneNumberId: PropTypes.string,
      hexColor: PropTypes.number,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          featureName: PropTypes.string,
        })
      ),
      expirationWarning: PropTypes.shape({
        id: PropTypes.number,
      }),
      alias: PropTypes.string,
      version: PropTypes.number,
      id: PropTypes.string,
      autoReplyActive: PropTypes.bool,
      dateCreated: PropTypes.number,
      lastUpdatedDate: PropTypes.number,
      renewalDate: PropTypes.number,
      ringer: PropTypes.bool,
      autoReplyType: PropTypes.number,
      remainingMinutes: PropTypes.number,
      productId: PropTypes.string,
      userId: PropTypes.string,
      autoReplyText: PropTypes.string,
      totalMinutes: PropTypes.number,
      notifications: PropTypes.bool,
      useSip: PropTypes.bool,
      totalTexts: PropTypes.number,
    })
  ),
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      price: PropTypes.number,
      store: PropTypes.string,
      burnersAssignedInPeriod: PropTypes.number,
      id: PropTypes.string,
      receipt: PropTypes.string,
      renewalDate: PropTypes.number,
      creationDate: PropTypes.number,
      burnerIds: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};

const defaultProps = {
  burners: null,
  subscriptions: null,
};

class BurnersList extends Component {
  renderExpiresLabel(subscriptions, burner) {
    if (isBurnerInSubscription(burner, subscriptions)) {
      return null;
    }

    const period = moment.duration(
      moment(burner.expirationDate).diff(moment())
    );
    const months = period.years() * 12 + period.months();
    const days = period.days();
    const hours = period.hours();

    if (months > 0) {
      return (
        <p>
          Expires in{' '}
          <span className="expires strong">
            {months} {months === 1 ? 'month' : 'months'} {days}{' '}
            {days === 1 ? 'day' : 'days'} {hours}{' '}
            {hours === 1 ? 'hour' : 'hours'}
          </span>
        </p>
      );
    } else if (months === 0 && days > 0) {
      return (
        <p>
          Expires in{' '}
          <span className="expires strong">
            {days} {days === 1 ? 'day' : 'days'} {hours}{' '}
            {hours === 1 ? 'hour' : 'hours'}
          </span>
        </p>
      );
    } else if (months === 0 && days === 0) {
      if (hours > 0) {
        return (
          <p>
            Expires in{' '}
            <span className="expire strong">
              {hours} {hours === 1 ? 'hour' : 'hours'}
            </span>
          </p>
        );
      } else if (hours === 0) {
        return (
          <p>
            Expires in <span className="expires strong">less than an hour</span>
          </p>
        );
      }
    }
  }

  renderBurnerType(burner, subscriptions) {
    if (isBurnerInSubscription(burner, subscriptions)) {
      return <p>Auto-renewing number</p>;
    }
  }

  renderUsageLeft(burner) {
    if (isUnlimited(burner)) {
      return (
        <div>
          <p>Unlimited texts & pics</p>
          <p>Unlimited minutes</p>
        </div>
      );
    }

    return (
      <div>
        <p>
          {burner.remainingTexts}{' '}
          {burner.remainingTexts === 1 ? 'text' : 'texts'} remaining
        </p>
        <p>
          {burner.remainingMinutes}{' '}
          {burner.remainingMinutes === 1 ? 'minute' : 'minutes'} remaining
        </p>
      </div>
    );
  }

  render() {
    const { burners, subscriptions } = this.props;
    let list;

    if (!burners) {
      list = null;
    } else {
      list = burners.map((burner) => (
        <div key={burner.id} className="cell large-6 small-12">
          <div className="LineDetail__box">
            <div className="LineDetail__heading grid-x align-left-middle">
              <div className="cell large-auto small-12">
                <h2 className="LineDetail__name">{burner.name}</h2>
              </div>
            </div>
            <div className="LineDetail__info">
              <h4 className="LineDetailInfo__heading">Number details</h4>
              <p>{renderLocalNumber(burner.phoneNumberId)}</p>
              {this.renderBurnerType(burner, subscriptions)}
              {this.renderUsageLeft(burner)}
              {this.renderExpiresLabel(subscriptions, burner)}
            </div>
          </div>
        </div>
      ));
    }
    return list;
  }
}

BurnersList.propTypes = propTypes;
BurnersList.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    subscriptions: state.dashboardPage.subscriptions,
  };
}

const mapDispatchToProps = actionCreators;

export default connect(mapStateToProps, mapDispatchToProps)(BurnersList);
